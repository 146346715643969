import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import { AdminUserComponent } from './dialogs/user/user.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firestore } from 'firebase';
import { environment } from '../../environments/environment';

export interface User {
  id: string;
  name: string;
  email: string;
  accountType: string | firestore.FieldValue;
  cardId: string;
  credits: number;
  createdAt: Date;
  rideProductId: string | firestore.FieldValue;
  icons: Map<string, boolean>;
}

export interface UserId extends User {
  id: string;
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit {
  itemsToShow = 20;
  newUserLink: string = environment.newUserLink;
  allUsers: Observable<UserId[]>;
  // filteredUsers: Observable<UserId[]>;
  public filteredUsers: ReplaySubject<UserId[]> = new ReplaySubject<UserId[]>(
    1
  );
  usersCollection: AngularFirestoreCollection<User>;
  displayedColumns = ['name', 'email', 'accountType'];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  // showHidden = false;
  // showHiddenChanged: Subject<boolean> = new Subject<boolean>();
  orderBy = 'name';
  orderByChanged: Subject<string> = new Subject<string>();
  orderDirection = 'asc';
  orderDirectionChanged: Subject<string> = new Subject<string>();

  totalUsers: number;
  plannerEmail: string;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.plannerEmail = user.email;
      }
    });
    this.usersCollection = this.db.collection<User>('users/', (ref) =>
      ref.orderBy('accountType')
    );
    this.allUsers = this.usersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as object;
          if (data['createdAt'] !== undefined) {
            data['createdAt'] = data['createdAt'].toDate();
          }
          const userData = data as User;
          if (!userData.credits) {
            userData.credits = 0;
          }
          const id = a.payload.doc.id;
          return { id, ...userData };
        })
      )
    );
    const combinedObservable = combineLatest(
      this.allUsers,
      this.searchQueryChanged,
      this.orderByChanged,
      this.orderDirectionChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const users = values[0];
        const searchQuery = values[1];
        const filters = {
          orderBy: values[2],
          orderDirection: values[3],
        };
        let filteredUsers = users.filter((item) =>
          this.checkFilters(item, filters)
        );
        // this.orderDirection = 'desc';
        console.log('orderBy', filters.orderBy);
        filteredUsers = filteredUsers.sort((a, b) => {
          if (a[filters.orderBy] > b[filters.orderBy]) {
            return 1;
          } else {
            return -1;
          }
          // return 0;
        });
        if (filters.orderDirection === 'desc') {
          filteredUsers.reverse();
        }
        console.log('filteredUsers', filteredUsers);
        this.totalUsers = filteredUsers.length;
        this.filteredUsers.next(filteredUsers);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQueryChanged.next('');
    this.orderByChanged.next(this.orderBy);
    this.orderDirectionChanged.next(this.orderDirection);
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    } else if (type === 'order') {
      this.orderByChanged.next(this.orderBy);
      this.orderDirectionChanged.next(this.orderDirection);
    }
  }

  checkFilters(user, filters) {
    this.itemsToShow = 20;
    // console.log('user', user);
    let passesSearchFilter = true;

    if (this.searchQuery) {
      passesSearchFilter = false;
      if (user.name) {
        if (user.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
      if (user.email) {
        if (user.email.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return user;
    }
  }

  async editUser(user?) {
    console.log('user', user);
    this.dialog.open(AdminUserComponent, {
      width: '500px',
      data: { user },
    });
  }
}
