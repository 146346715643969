import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import { EditAddressComponent } from '../frequent-address/dialogs/edit-address/edit-address.component';
import { ImportExportComponent } from '../dialog/import-export/import-export.component';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';

export interface Address {
  address: string;
  name: string;
}
export interface AddressId extends Address {
  id: string;
}

@Component({
  selector: 'app-frequent-address',
  templateUrl: './frequent-address.component.html',
  styleUrls: ['./frequent-address.component.scss'],
})
export class FrequentAddressComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  displayedColumns = ['name', 'address', 'buttons'];
  addressesCollection: AngularFirestoreCollection<Address>;
  allAddresses: Observable<AddressId[]>;
  importedData: Subject<AddressId[]> = new ReplaySubject<AddressId[]>();
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  public filteredAddresses: ReplaySubject<AddressId[]> = new ReplaySubject<
    AddressId[]
  >(1);
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  totalAddresses: number;
  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.addressesCollection = this.db.collection<Address>('addresses', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.allAddresses = this.addressesCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Address;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.allAddresses.subscribe((address) => {
      console.log('allAddresses', address);
    });
    const combinedObservable = combineLatest(
      this.allAddresses,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const items = values[0];
        const searchQuery = values[1];
        // const filters = {
        //   showHidden: values[2],
        //   orderBy: values[3],
        //   orderDirection: values[4]
        // };
        let filteredItem = items.filter((item) => this.checkFilters(item));
        // this.orderDirection = 'desc';
        // console.log('orderBy', filters.orderBy);
        // filteredVehicles = filteredVehicles.sort((a, b) => {
        //   if (a[filters.orderBy] > b[filters.orderBy]) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }
        //   // return 0;
        // });
        // if (filters.orderDirection === 'desc') {
        //   filteredVehicles.reverse();
        // }
        console.log('filteredItem', filteredItem);
        this.totalAddresses = filteredItem.length;
        this.filteredAddresses.next(filteredItem);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQueryChanged.next('');
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(item) {
    // console.log('user', user);
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (item.name) {
        if (item.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
      if (item.address) {
        if (
          item.address.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return item;
    }
  }

  newAddress(isNew) {
    this.dialog.open(EditAddressComponent, {
      width: '500px',
      data: { newAddress: isNew },
    });
  }

  editAddress(isNew, address) {
    console.log('editAddress', address);
    this.dialog.open(EditAddressComponent, {
      width: '500px',
      data: { newAddress: isNew, address },
    });
  }

  async deleteAddress(address) {
    console.log('deleteAddress', address.id);
    await this.db.doc(`addresses/${address.id}`).delete();
    this._snackBar.open('Adres verwijdert!', '', {
      duration: 5000,
    });
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result === 'Export') {
        this.exportTableToExcel();
      }
      if (result === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }
  async exportTableToExcel() {
    const itemRef = this.db.collection<Address>('addresses');
    const itemArray = [];
    const itemObservable = await itemRef.get();
    await itemObservable.forEach((vehicles) => {
      // console.log('vouchers', vouchers);
      vehicles.forEach((vehicleDoc) => {
        const obj = vehicleDoc.data() as Address;
        const exportObj = {};
        exportObj['Naam'] = obj.name ? obj.name : '';
        exportObj['Adres'] = obj.address ? obj.address : '';
        itemArray.push(exportObj);
      });
    });
    if (itemArray.length > 0) {
      console.log('vouchers', itemArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(itemArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Addresses');

      // /* save to file */
      XLSX.writeFile(wb, 'veel_voorkomende_adressen.xlsx');
    } else {
      // Nothing to export
    }
  }
  uploadedFile(event) {
    if (this.uploader.nativeElement.value === null) {
      return;
    } else {
      console.log('event', event);
      console.log('uploading file');
      this.fileUploaded = event.target.files[0];
      this.readExcel();
    }
  }
  readExcel() {
    const readFile = new FileReader();
    const spreadsheet = {};
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins') {
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'naam':
            columnNames[key] = 'name';
            break;
          case 'adres':
            columnNames[key] = 'address';
            break;
        }
      });
      delete spreadsheet[1];
      const importedData = [];
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {};
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log(spreadsheet[key][colKey]);
          // console.log('colName', colName);
          if (colName) {
            if (colName === 'name') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'address') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });
        console.log('rowObj', rowObj);
        importedData.push(rowObj);
      });
      this.importedData.next(importedData);
      this.fileChangeListener();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }
  fileChangeListener() {
    this.addressesCollection = this.db.collection<Address>('addresses');
    this.allAddresses = this.addressesCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Address;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.allAddresses.subscribe((value) => {
      console.log('allAddresses', value);
      // this.totalVehicles = value.length;
    });
    this.importedData.subscribe((val) => {
      console.log('this.importedData', val);
    });
    try {
      const combinedObservable = combineLatest(
        this.allAddresses,
        this.importedData
      );
      combinedObservable
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((res: [AddressId[], Array<any>]) => {
          console.log('res', res);
          const existingAddress = res[0];
          const importedAddress = res[1];
          if (importedAddress === null) {
            return;
          }
          // check for existingVolunteers with importedVolunteers' data, update their info.
          // create new entry for all newly imported volunteers, process account creation in functions trigger.
          importedAddress.forEach(async (data) => {
            console.log('data', data);
            let address;
            let name;
            // tslint:disable-next-line: prefer-const
            // this section looks for a recognized column title and matches it with the correct userData
            if (data.name) {
              name = data.name;
            }
            if (data.address) {
              address = data.address;
            }
            const importData: Address = { name, address };
            //
            const existingData = existingAddress.find((element) => {
              return element.name === importData.name;
            });

            console.log('existingData', existingData);
            if (existingData) {
              // update existing doc

              await this.addressesCollection
                .doc(existingData.id)
                .set(importData, { merge: true });
            } else {
              // create new doc
              await this.addressesCollection.add(importData);
            }
            this.importedData.next(null);
            this.uploader.nativeElement.value = null;
          });

          this._snackBar.open('Het adressen zijn geimporteerd', 'X', {
            duration: 5000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
}
