import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { WindowService } from '../../window.service';
import * as firebase from 'firebase';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { environment } from '../../../environments/environment';

export interface User {
  email: string;
  firstName: string;
  phone: string;
  acceptPrivacy: boolean;
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  items: Observable<User[]>;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  acceptPrivacy: boolean;
  password: string;
  confirmPassword: string;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  // video: string = environment.login.video;
  image: string = environment.login.image;
  name: string = environment.name.name;
  prefix: string = environment.phone.nl;

  verificationCode: string;
  windowRef: any;
  user: any;

  checkSendSMS: boolean;

  matcher = new MyErrorStateMatcher();
  constructor(
    private win: WindowService,
    public afAuth: AngularFireAuth,
    private Router: Router,
    private afs: AngularFirestore,
    private _snackBar: MatSnackBar
  ) {
    this.phone = this.prefix;
  }
  async sendSignUpCode() {
    const num = this.phone;
    const appVerifier = this.windowRef.recaptchaVerifier;

    (await this.afAuth.app)
      .auth()
      .signInWithPhoneNumber(num, appVerifier)
      .then((result) => {
        this.windowRef.confirmationResult = result;
        this.checkSendSMS = true;
      })
      .catch((error) => console.log(error));
  }

  async verifySignUpCode() {
    await this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then((res) => {
        this.user = res.user;
        console.log('user =>', this.user);
        const userDoc = this.afs.doc('users/' + res.user.uid);
        let user = { firstName: this.firstName, phone: this.phone };
        userDoc.set(user);
        this.Router.navigate(['/profile'], {});
      })
      .catch((error) => console.log(error, 'Incorrect code entered?'));
  }

  async SignUpEmail() {
    if (this.password === this.confirmPassword) {
      (await this.afAuth.app)
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((res) => {
          this.phone = this.prefix + this.phone;
          const userDoc = this.afs.doc('users/' + res.user.uid);
          let user = {
            firstName: this.firstName,
            email: this.email,
            phone: this.phone,
          };
          userDoc.set(user);
          console.log('Successfully signed up!', res);
          this.Router.navigate(['/profile'], {});
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });
    } else {
      this._snackBar.open(
        'Wachtwoord en herhaal wachtwoord komen niet overeen',
        '',
        {
          duration: 5000,
        }
      );
      console.log('Confirm password is not correct');
    }
  }

  ngOnInit() {
    firebase.auth().languageCode = 'nl';
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container'
    );

    this.windowRef.recaptchaVerifier.render();
  }
}
