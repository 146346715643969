import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  VehiclesComponent,
  Vehicle,
  VehicleId,
} from '../../../vehicles/vehicles.component';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
// import { ThemePalette } from '@angular/material';
import { Setting } from './../../../../interfaces';

@Component({
  selector: 'app-create-edit-vehicles',
  templateUrl: './create-edit-vehicles.component.html',
  styleUrls: ['./create-edit-vehicles.component.scss'],
})
export class CreateEditVehiclesComponent implements OnInit {
  vehicleForm: FormGroup;
  vehicle: VehicleId;
  color: string;
  toggle: boolean;
  isNew: boolean;
  isActive: boolean;
  totalActive: number;
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  constructor(
    private fb: FormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>
  ) {}

  ngOnInit() {
    this.settingDoc = this.db.doc<Setting>('settings/general');
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      console.log('this.settings', val);
    });
    if (this.data.vehicle) {
      this.vehicle = this.data.vehicle;
      this.isActive = this.data.vehicle.active;
      console.log('vehicle', this.vehicle);
    } else {
      this.isActive = false;
      this.isNew = true;
      this.color = '#ffffff';
    }
    this.totalActive = this.data.totalActive;
    this.vehicleForm = this.fb.group({
      name: ['', Validators.required],
      seats: ['', Validators.required],
      color: [this.color, Validators.required],
      active: [],
    });
    this.vehicleForm.get('color').valueChanges.subscribe((value) => {
      console.log('value changed', value);
      if (value === '') {
        console.log('value empty');
        value = '#';
        this.color = '#';
      }
    });
    if (this.vehicle) {
      let formVal: any = this.vehicle;
      if (this.vehicle.calendarStyle.color) {
        formVal.color = this.vehicle.calendarStyle.color;
        this.color = this.vehicle.calendarStyle.color;
      }

      console.log('formval', formVal);
      this.vehicleForm.patchValue(this.vehicle);
    }
    console.log('this.taskForm', this.vehicleForm.value);
  }

  async onSubmit() {
    console.log('itemForm', this.vehicleForm);
    const formValue = this.vehicleForm.value;
    console.log('formValue', formValue);
    // tslint:disable-next-line: prefer-const
    if (this.isNew) {
      formValue.id = this.db.createId();
    } else {
      formValue.id = this.vehicle.id;
    }

    if (formValue.color) {
      formValue.calendarStyle = {};
      formValue.calendarStyle.color = this.color;
      delete formValue.color;
    }

    let submitItem = formValue;

    await this.saveItem(submitItem);
  }
  async saveItem(submitItem) {
    console.log('submitItem', submitItem);
    // try {
    const batch = this.db.firestore.batch();
    batch.set(
      this.db.collection('vehicles').doc(submitItem.id).ref,
      submitItem,
      { merge: true }
    );

    await batch.commit();
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
