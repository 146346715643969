<app-nav-bar></app-nav-bar>
<!-- HEADER -->
<div class="header p-16 p-sm-24">
  <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
    <div
      class="header-top"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayout.xs="column"
    >
      <div
        class="logo mb-16 mb-sm-0"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <mat-icon class="logo-icon"> today </mat-icon>
        <span class="logo-text"> Planner </span>
      </div>

      <mat-form-field class="vehicle">
        <!-- <mat-label>Zie ritten van voertuig</mat-label> -->
        <mat-select
          [(ngModel)]="filterOnVehicle"
          required
          (selectionChange)="vehicleChanged()"
        >
          <mat-option value="all"> Alle voertuigen</mat-option>
          <mat-option
            *ngFor="let vehicle of vehicles | async"
            [value]="vehicle.id"
          >
            {{ vehicle.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- TOOLBAR -->
      <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
        <button
          matTooltip="Vandaag"
          mat-icon-button
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="fetchEvents()"
        >
          <mat-icon>today</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="setView(CalendarView.Day)"
          matTooltip="Dagweergave"
        >
          <mat-icon>view_day</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="setView(CalendarView.Week)"
          matTooltip="Weekweergave"
        >
          <mat-icon>view_week</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="setView(CalendarView.Month)"
          matTooltip="Maandweergave"
        >
          <mat-icon>view_module</mat-icon>
        </button>
      </div>
    </div>
    <!-- / TOOLBAR -->

    <!-- HEADER BOTTOM -->
    <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-icon-button
        class="arrow"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="fetchEvents()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>

      <div class="title" *ngIf="view === CalendarView.Day">
        {{ weekdays[viewBeginDate.getDay()] }}
        {{ viewBeginDate | date: 'd MMM y' }}
        <!-- {{ viewDate | calendarDate:(view + 'ViewTitle'):'nl' }} -->
      </div>
      <div class="title" *ngIf="view === CalendarView.Week">
        {{ viewBeginDate | date: 'd MMM' }} -
        {{ viewEndDate | date: 'd MMM y' }}
        <!-- {{ viewDate | calendarDate:(view + 'ViewTitle'):'nl' }} -->
      </div>
      <div class="title" *ngIf="view === CalendarView.Month">
        {{ viewBeginDate | date: 'MMMM y' }}
        <!-- {{ viewDate | calendarDate:(view + 'ViewTitle'):'nl' }} -->
      </div>

      <button
        mat-icon-button
        class="arrow"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="fetchEvents()"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <!-- / HEADER BOTTOM -->
  </div>
</div>

<ng-template #loading>
  <div id="loading" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div *ngIf="events$ | async; else loading; let events">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      weekStartsOn="1"
      [viewDate]="viewDate"
      [events]="events"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="eventClicked($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      [dayStartHour]="env.startHour"
      [dayEndHour]="env.endHour"
      weekStartsOn="1"
      [hourSegments]="4"
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [events]="events"
      (eventClicked)="eventClicked($event.event)"
      (hourSegmentClicked)="newRide($event.date)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      [dayStartHour]="env.startHour"
      [dayEndHour]="env.endHour"
      [hourSegments]="4"
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="events"
      (eventClicked)="eventClicked($event.event)"
      (hourSegmentClicked)="newRide($event.date)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
</div>

<button mat-fab class="fab-fixed" (click)="newRide()">
  <mat-icon>add</mat-icon>
</button>
