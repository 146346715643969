import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TariffsComponent } from '../../../tariffs/tariffs.component';
import { TariffId } from './../../../../interfaces';

@Component({
  selector: 'app-create-edit-tariff',
  templateUrl: './create-edit-tariff.component.html',
  styleUrls: ['./create-edit-tariff.component.scss'],
})
export class CreateEditTariffComponent implements OnInit {
  tariffForm: FormGroup;
  tariff: TariffId;
  toggle: boolean;
  isNew: boolean;

  constructor(
    private fb: FormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TariffsComponent>
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    if (this.data == null) {
      this.isNew = true;
    } else if (this.data.tariff) {
      this.tariff = this.data.tariff;
      console.log('tariff', this.tariff);
    }
    this.tariffForm = this.fb.group({
      name: ['', Validators.required],
      cost: ['', Validators.required],
    });
    if (this.tariff) {
      const formVal: any = this.tariff;

      console.log('formval', formVal);
      this.tariffForm.patchValue(formVal);
    }
    console.log('this.taskForm', this.tariffForm.value);
  }
  async onSubmit() {
    console.log('itemForm', this.tariffForm);
    const formValue = this.tariffForm.value;
    console.log('formValue', formValue);
    // tslint:disable-next-line: prefer-const
    if (this.isNew) {
      formValue.id = this.db.createId();
    } else {
      formValue.id = this.tariff.id;
    }

    const submitItem = formValue;

    await this.saveItem(submitItem);
  }
  async saveItem(submitItem) {
    console.log('submitItem', submitItem);
    const batch = this.db.firestore.batch();
    batch.set(
      this.db.collection('products').doc(submitItem.id).ref,
      submitItem,
      { merge: true }
    );

    await batch.commit();
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
