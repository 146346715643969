<!-- <h1 mat-dialog-title *ngIf="data.ride.id">Rit bewerken</h1>
<h1 mat-dialog-title *ngIf="!data.ride.id">Nieuwe rit</h1> -->
<!-- <app-user-ride></app-user-ride> -->
<div class="ride-dialog">
    <div mat-dialog-content>
        <div *ngIf="!userSelected">
            <mat-form-field class="search">
                <mat-label>Zoeken op naam, e-mail of adres</mat-label> 
                <input matInput (keyup.enter)="select(searchQuery)" [(ngModel)]="searchQuery"
                    (ngModelChange)="onFilterChange('search', $event, searchQuery)" [matAutocomplete]="auto"
                    matAutocompletePosition="below" [ngClass]="{'searching': autoCompleteOpen && itemsShown}">
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete" (opened)="autoCompleteOpen = true"
                (closed)="autoCompleteOpen = false">
                <mat-option (click)="select(user)" *ngFor="let user of filteredUsers | async" [value]="user">
                    {{user.name}} ({{user.credits}})
                </mat-option>
                <mat-option class="more" *ngIf="itemsShown < potentialItems" [value]="searchQuery" routerLink="/filter"
                    routerLinkActive="active" [queryParams]="{q: searchQuery}">
                    Om meer resultaten te zien zoek specifieker.
                </mat-option>
            </mat-autocomplete>
        </div>
        <form *ngIf="userSelected" fxLayout="column" [formGroup]="userForm">
            <mat-label class="title">{{user['title']}}</mat-label>
            <div class="icons" fxLayout="row">
                <div class="icon" [ngClass]="{'active': user[icon.id] === true}" [matTooltip]="icon.name"
                    *ngFor="let icon of data.icons">
                    <mat-icon *ngIf="icon.iconType == 'material'">
                        {{icon.icon}}</mat-icon>
                    <div class="mat-icon" *ngIf="icon.iconType == 'custom'"
                        [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"></div>
                </div>
            </div>
            <div class="icon-toggles" *ngFor="let icon of data.icons">
                <mat-slide-toggle (change)="onIconChange(icon.id, $event)" *ngIf="icon.editableInRide"
                    formControlName="{{icon.id}}">
                    {{icon.name}}
                </mat-slide-toggle>
            </div>
            <!-- <mat-checkbox formControlName="companion">Neemt een begleider mee</mat-checkbox> -->
            <mat-form-field class="type-select">
                <mat-label>Van</mat-label>
                <mat-select formControlName="fromType" required (selectionChange)="onTypeChange('from', $event)">
                    <mat-option *ngFor="let type of locationTypes" [value]="type.val">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!fromHidden">
                <mat-label>Adres</mat-label>
                <input formControlName="from" placeholder="mijn straat 1, mijn dorp" matInput required
                    [matAutocomplete]="fromAuto">
                <mat-autocomplete autoActiveFirstOption #fromAuto="matAutocomplete">
                    <!-- <mat-form-field class="full-width" appearance="standard">
                        <input type="search" formControlName="fromSearch" placeholder="Zoekfunctie" matInput>
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearInput('from')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field> -->
                    <mat-option *ngFor="let option of predictionFrom | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field *ngIf="fromHidden">
                <mat-label>Adres</mat-label>
                <mat-select formControlName="from" required>
                    <mat-form-field class="full-width" appearance="standard">
                        <!-- <mat-label>Zoekfunctie</mat-label> -->
                        <input type="search" formControlName="fromSearch" placeholder="Zoekfunctie" matInput autocomplete="off">
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearInput('from')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let address of addressesFrom | async" [value]="address.id">{{address.name}},
                        {{address.address}}
                    </mat-option>
                    <mat-option *ngIf='nothingFound'>
                        <p>Geen resultaten gevonden</p>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="type-select">
                <mat-label>Naar</mat-label>
                <mat-select formControlName="toType" required (selectionChange)="onTypeChange('to', $event)">
                    <mat-option *ngFor="let type of locationTypes" [value]="type.val">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!toHidden">
                <mat-label>Adres</mat-label>
                <input formControlName="to" placeholder="mijn straat 1, mijn dorp" matInput required
                    [matAutocomplete]="toAuto">
                <mat-autocomplete autoActiveFirstOption #toAuto="matAutocomplete">
                    <mat-option *ngFor="let option of predictionTo | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field *ngIf="toHidden">
                <mat-label>Adres</mat-label>
                <mat-select formControlName="to" required>
                    <mat-form-field class="full-width" appearance="standard">
                        <!-- <mat-label>Zoekfunctie</mat-label> -->
                        <input type="search" formControlName="toSearch" placeholder="Zoekfunctie" matInput autocomplete="off">
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearInput('to')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let address of addressesTo | async" [value]="address.id">{{address.name}},
                        {{address.address}}
                    </mat-option>
                    <mat-option *ngIf='nothingFound'>
                        <p>Geen resultaten gevonden</p>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Opmerkingen voor planner</mat-label>
                <textarea formControlName="remarks" matInput></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Opmerkingen voor chauffeur</mat-label>
                <textarea formControlName="commentsForDriver" matInput></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Aangepaste ritprijs</mat-label>
                <mat-select formControlName="rideProduct">
                    <mat-option style="font-style: italic;" [value]="'none'">Geen
                    </mat-option>
                    <mat-option *ngFor="let product of products | async" [value]="product.id">{{product.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Annuleren</button>
        <button mat-raised-button [disabled]="!userForm.valid" (click)="save()" color="primary">Opslaan</button>
    </div>
</div>