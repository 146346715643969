import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditRideComponent } from '../edit-ride/edit-ride.component';
import { CopyRideComponent } from '../copy-ride/copy-ride.component';
import { Ride, Vehicle } from '../../planner.component';
import { Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Icon, IconId } from 'src/app/users/dialogs/user/user.component';
import { map, take } from 'rxjs/operators';

export interface DialogData {
  ride: Ride;
  vehicle: Vehicle;
}

@Component({
  selector: 'app-ride-details',
  templateUrl: './ride-details.component.html',
  styleUrls: ['./ride-details.component.scss']
})
export class RideDetailsComponent implements OnInit {
  users: Array<any>;
  icons: Observable<IconId[]>;
  iconsCollection: AngularFirestoreCollection<Icon>;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public dialog: MatDialog,
    public dialogRef: MatDialogRef<RideDetailsComponent>) { }

  ngOnInit() {
    console.log('ride', this.data.ride);
    if (this.data.ride.users) {
      Object.keys(this.data.ride.users).forEach((key) => {
        this.data.ride.users[key].id = key;
      });
      this.users = Object.values(this.data.ride.users).sort((x, y) => {
        return x.order - y.order;
      });
    }
    console.log('users', this.users);
    this.iconsCollection = this.db.collection<Icon>('icons', ref => ref
      .orderBy('name', 'asc'));
    this.icons = this.iconsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Icon;
        const id = a.payload.doc.id;
        return { id, ...data };
      })), take(1)
    );
  }

  editRide() {
    this.dialogRef.close();
    this.dialog.open(EditRideComponent, {
      width: '500px',
      data: { ride: this.data.ride }
    });
  }

  copyRide() {
    this.dialogRef.close();
    this.dialog.open(CopyRideComponent, {
      width: '500px',
      data: { ride: this.data.ride }
    });
  }
}
