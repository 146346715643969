import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
// import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, Event } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
// import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { ConstantsService } from './../common/services/constants.service';

import { environment } from '../../environments/environment';

export interface User {
  email: string;
  accountType: string;
}
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: any;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  @ViewChild('toggleButton', { static: false }) toggleButton: ElementRef;
  userDoc: AngularFirestoreDocument<User>;
  userId: Observable<string>;
  user: Observable<User>;
  fixed = true;
  userEmail: string;
  accountType: string;

  constructor(
    private route: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public constant: ConstantsService,
    private renderer: Renderer2
  ) {
    // this.constant.user.subscribe(userData => {
    //   this.user = userData;
    // });
    // this.userEmail = this.constant.userEmail;
    // this.user = this.constant.user;
  }

  async logout() {
    console.log('User pressed button');
    await (await this.afAuth.app).auth().signOut();
    return this.route.navigate(['/login']);
  }

  checkRoute() {
    let check = false;
    this.route.events.subscribe((event: Event) => {
      check = true;
    });
    if (!check) {
      this.sidenav.close();
    }
  }

  // logout() {
  //   console.log("user logged out");
  //   this.afAuth.auth.signOut();
  // }

  ngOnInit() {
    this.afAuth.user.subscribe(async (user) => {
      if (user) {
        // console.log('user', user);
        this.userEmail = user.email;
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe((res) => {
          // console.log('UserData =>', res);
          this.accountType = res.accountType;
          if (res.accountType === 'planner' || res.accountType === 'admin') {
            // you can stay ^^
          } else {
            // Log user out if he has no rights for the planning environment.
            this.logout();
          }
        });
        console.log('environment.name.name', environment.name.name);
        (await this.afAuth.app).auth().currentUser.getIdToken(true);
        // this.afs
        //   .doc<User>(`claimsSet/${user.uid}`)
        //   .valueChanges()
        //   .subscribe(async (res) => {
        //     console.log('res', res);
        //     (await this.afAuth.app).auth().currentUser.getIdToken(true); // refresh user token with new Claims data to show correct UI
        //     // above code gives sometimes errors needs a delay
        //   });
      }
    });
    this.renderer.listen('window', 'click', (event) => {
      if (
        !this.toggleButton.nativeElement.contains(event.target) &&
        !this.menu.nativeElement.contains(event.target)
      ) {
        this.sidenav.close();
      }
      if (
        this.toggleButton.nativeElement.contains(event.target) &&
        this.menu.nativeElement.contains(event.target)
      ) {
        console.log('Nothing');
      }
    });
  }
}
