<div class="height-saver"></div>
<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <div #toggleButton>
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon class="s-16">menu</mat-icon>
        </button>
      </div>
    </div>

    <div class="navbar-separator" fxHide.gt-xs></div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar-picture mr-0 mr-sm-16" src="../../assets/profile-picture-placeholder/Profile-Placeholder-Round.png"> -->
          <ngx-avatar
            textSizeRatio="2"
            class="profile-avatar avatar-picture"
            name="{{ userEmail }}"
            size="40"
            [round]="true"
          ></ngx-avatar>
          <span class="username mr-0 mr-sm-16" fxHide fxShow.gt-xs>{{
            userEmail
          }}</span>
          <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Uitloggen</span>
        </button>
      </mat-menu>

      <!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="side"
    closed
    class="sidenav"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
  >
    <div #menu class="side-nav" fxLayout="column">
      <div
        class="side-nav-top"
        fxFlex
        fxFill
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div
          class="header-logo"
          fxFlex="1 0 auto"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <img class="logo" />
          <!-- <ngx-avatar textSizeRatio="2" class="profile-avatar avatar-picture" name="{{ userEmail }}" size="40" [round]="true"></ngx-avatar> -->
        </div>
        <div
          class="menu-button"
          fxFlex="0 1 auto"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon class="s-16">menu</mat-icon>
          </button>
        </div>
      </div>
      <div class="side-nav-content">
        <div class="content">
          <button
            class="button"
            mat-flat-button
            routerLink="/planner"
            (click)="checkRoute()"
          >
            <mat-icon>calendar_today</mat-icon>
            <span>Planning</span>
          </button>
        </div>
        <div class="content">
          <button
            class="button"
            mat-flat-button
            routerLink="/users"
            (click)="checkRoute()"
          >
            <mat-icon>people</mat-icon>
            <span>Deelnemers</span>
          </button>
        </div>
        <!-- *ngIf="this.accountType === 'admin' -->
        <div class="content">
          <mat-expansion-panel
            mat-flat-button
            class="button"
            style="box-shadow: none"
          >
            <mat-expansion-panel-header
              class="side-expension"
              [collapsedHeight]="'37.51px'"
              [expandedHeight]="'37.51px'"
            >
              <mat-panel-title class="button" fxLayoutAlign="start center">
                <mat-icon>settings</mat-icon>
                Instellingen
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button
              *ngIf="this.accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settings"
              (click)="checkRoute()"
            >
              <span>Algemeen</span>
            </button>
            <button
              *ngIf="
                this.accountType === 'admin' || this.accountType === 'planner'
              "
              class="button-expanded"
              mat-flat-button
              routerLink="/audit"
              (click)="checkRoute()"
            >
              <span>Audit</span>
            </button>
            <button
              *ngIf="this.accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/admin-users"
              (click)="checkRoute()"
            >
              <span>Gebruikersrechten</span>
            </button>
            <button
              *ngIf="this.accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsTariffs"
              (click)="checkRoute()"
            >
              <span>Tarieven</span>
            </button>
            <button
              *ngIf="this.accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/frequentAddress"
              (click)="checkRoute()"
            >
              <span>Veelvoorkomende adressen</span>
            </button>
            <button
              *ngIf="this.accountType === 'admin'"
              class="button-expanded"
              mat-flat-button
              routerLink="/settingsVehicles"
              (click)="checkRoute()"
            >
              <span>Voertuigen</span>
            </button>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
