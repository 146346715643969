<div>
    <form fxLayout="column" (ngSubmit)="onSubmit()" [formGroup]="tariffForm">
        <mat-form-field>
            <mat-label>Tariefnaam</mat-label>
            <input placeholder="Tariefnaam" formControlName="name" matInput required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Credits kosten</mat-label>
            <input type="number" placeholder="Credits kosten" formControlName="cost" matInput required>
        </mat-form-field>
        <!-- <div *ngIf="setting | async as setting">
            <mat-slide-toggle [disabled]="totalDefault >= 1 && !isDefault" formControlName="active">
                Actief
            </mat-slide-toggle>
        </div> -->
        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="6px">
            <button mat-flat-button type="button" (click)="close()">Annuleren</button>
            <button mat-flat-button type="submit" [disabled]="tariffForm.invalid">Opslaan</button>
        </div>
    </form>
</div>