import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { User } from '../../admin-users.component';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable, combineLatest, Subject, ReplaySubject } from 'rxjs';
import { map, take, takeUntil, debounceTime } from 'rxjs/operators';
import {
  Product,
  ProductId,
} from 'src/app/planner/dialogs/edit-ride/dialogs/user-ride/user-ride.component';
import { firestore } from 'firebase';
import { UserId } from '../../../interfaces';
import { HttpClient } from '@angular/common/http';
import { Vehicle, VehicleId } from 'src/app/planner/planner.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

export interface DialogData {
  user: any;
}

export interface Icon {
  name: string;
  icon: string;
  iconType: string;
  takesSeat: boolean;
  editableInRide: boolean;
}

export interface IconId extends Icon {
  id: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class AdminUserComponent implements OnInit {
  user: User;
  userForm: FormGroup;
  newUser = true;
  userSelected = false;
  saving = false;

  vehicles: Observable<VehicleId[]>;
  vehicleCollection: AngularFirestoreCollection<Vehicle>;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AdminUserComponent>,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      accountType: ['driver', Validators.required],
      // defaultVehicleId: [''],
    });
    if (this.data.user) {
      this.newUser = false;
      this.user = this.data.user;
      this.userSelected = true;
      this.userForm.patchValue(this.data.user);
    } else {
      this.userForm.registerControl(
        'email',
        new FormControl('', [Validators.email, Validators.required])
      );
      this.userForm.registerControl(
        'password',
        new FormControl('', Validators.required)
      );
    }
    this.vehicleCollection = this.db.collection<Vehicle>('vehicles', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.typeChanged();
  }

  typeChanged() {
    console.log('type', this.userForm.value.accountType);
    if (this.userForm.value.accountType === 'driver') {
      this.userForm.registerControl(
        'defaultVehicleId',
        new FormControl('', Validators.required)
      );
    } else {
      this.userForm.removeControl('defaultVehicleId');
    }
  }

  async save() {
    if (this.userForm.valid && !this.saving) {
      console.log('userForm', this.userForm);
      this.saving = true;
      if (this.newUser) {
        this.setNewRights();
      } else {
        const saveObj = this.userForm.value as User;
        Object.keys(saveObj).forEach((key) => {
          console.log('key', key);
          if (saveObj[key] == null) {
            delete saveObj[key];
          }
        });
        if (saveObj.accountType === 'none' || !saveObj.accountType) {
          saveObj.accountType = firestore.FieldValue.delete();
        }
        console.log('saveObj', saveObj);
        await this.db.collection('users').doc(this.user.id).update(saveObj);
        this.saving = false;
        this.dialogRef.close();
      }
    }
  }

  setNewRights() {
    const httpRequest = this.http.post(
      environment.functionsUrl + '/grantRightsGroup',
      {
        email: this.userForm.controls.email.value,
        password: this.userForm.controls.password.value,
        name: this.userForm.controls.name.value,
        accountType: this.userForm.controls.accountType.value,
        defaultVehicleId: this.userForm.controls.defaultVehicleId
          ? this.userForm.controls.defaultVehicleId.value
          : 'null',
      },
      { responseType: 'text' }
    );
    httpRequest.subscribe(
      (result: string) => {
        console.log('result', result);
        if (result === 'success') {
          this.snackBar.open('De gebruiker heeft rechten gekregen.', '', {
            duration: 5000,
          });
          this.dialogRef.close();
        } else if (result === 'email_already_exists') {
          this.snackBar.open(
            'Dit e-mailadres heeft al een account en kan daarom niet gebruikt worden.',
            '',
            {
              duration: 5000,
            }
          );
        } else if (result === 'error_creating_user') {
          this.snackBar.open(
            'Er is een onbekende fout opgetreden bij het aanmaken van het account, neem a.u.b. contact op.',
            '',
            {
              duration: 5000,
            }
          );
        }
        this.saving = false;
      },
      (error) => {
        console.log('error', error);
        setTimeout(() => this.setNewRights(), 2000);
      }
    );
  }
}
