<h1 mat-dialog-title>
  {{ data.user.name }} <span>({{ data.user.credits }})</span>
</h1>
<div mat-dialog-content>
  <form fxLayout="column" (ngSubmit)="save()" [formGroup]="userForm">
    <mat-form-field>
      <mat-label>Naam</mat-label>
      <input formControlName="name" matInput required />
    </mat-form-field>
    <mat-form-field fxFlex="70">
      <mat-label>Straatnaam</mat-label>
      <input formControlName="streetName" matInput required />
    </mat-form-field>
    <mat-form-field fxFlex="30">
      <mat-label>Huisnummer</mat-label>
      <input formControlName="houseNumber" matInput required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Postcode</mat-label>
      <input
        formControlName="postal"
        matInput
        #postalCode
        maxlength="7"
        required
      />
      <mat-hint align="end">{{ postalCode.value.length }} / 7</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Woonplaats</mat-label>
      <input formControlName="city" matInput required />
    </mat-form-field>
    <!-- <mat-form-field>
            <mat-label>Geboortedatum</mat-label>
            <input matInput [matDatepicker]="date" formControlName="birthdate" required>
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
        </mat-form-field> -->
    <mat-form-field>
      <mat-label>Telefoonnummer</mat-label>
      <input placeholder="06 11223344" formControlName="phoneNumber" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Kaartnummer</mat-label>
      <input formControlName="cardId" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Opmerkingen voor planner</mat-label>
      <textarea formControlName="remarks" matInput></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Opmerkingen voor chauffeur</mat-label>
      <textarea formControlName="commentsForDriver" matInput></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Standaard ritprijs</mat-label>
      <mat-select formControlName="rideProductId">
        <mat-option style="font-style: italic" [value]="'none'"
          >Geen
        </mat-option>
        <mat-option
          *ngFor="let product of products | async"
          [value]="product.id"
          >{{ product.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <!-- <mat-slide-toggle formControlName="rollator">Ik wil mijn rollator meenemen</mat-slide-toggle>
        <mat-slide-toggle formControlName="guideDog">Ik wil mijn geleidehond meenemen</mat-slide-toggle>
        <mat-slide-toggle formControlName="cane">Ik ben slecht ter been</mat-slide-toggle>
        <mat-slide-toggle formControlName="escort">Ik heb begleiding nodig</mat-slide-toggle>
        <mat-slide-toggle formControlName="companion">Ik neem een begleider mee</mat-slide-toggle>
        <mat-slide-toggle formControlName="wheelchair">Ik wil mijn rolstoel meenemen</mat-slide-toggle>
        <mat-slide-toggle formControlName="freeUser">Deelnemer heeft recht op gratis reizen</mat-slide-toggle> -->
    <div formGroupName="icons" fxLayout="column">
      <mat-slide-toggle
        *ngFor="let icon of icons | async"
        formControlName="{{ icon.id }}"
      >
        {{ icon.description }}
      </mat-slide-toggle>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Annuleren</button>
  <button
    mat-raised-button
    [disabled]="!userForm.valid"
    (click)="save()"
    cdkFocusInitial
    color="primary"
  >
    Opslaan
  </button>
</div>
