<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="h-title" fxFlex>
      <mat-icon>credit_card</mat-icon>
      <span>Tarieven beheren</span>
    </div>
    <button mat-raised-button style="height: 40px" (click)="openImportExport()">
      <mat-icon>import_export</mat-icon><span fxHide.xs>Importeren/Exporteren</span>
    </button>
  </div>
  <mat-card *ngIf="tariffs; else loading" style="padding: 24px">
    <div class="title" fxLayout="row">
      <h3 fxFlex>
        Alle tarieven <span>({{ totalTariffs }})</span>
      </h3>
      <div class="search" fxLayoutAlign=" center">
        <mat-form-field>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input matInput placeholder="Zoeken" [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)" />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table mat-table id="tblData" #table [dataSource]="filteredTariffs">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
          <mat-cell *matCellDef="let tariff">
            <p class="text-truncate" *ngIf="tariff.name">{{ tariff.name }}</p>
          </mat-cell>
        </ng-container>

        <!-- seats Column -->
        <ng-container matColumnDef="credits">
          <mat-header-cell *matHeaderCellDef>Aantal credits</mat-header-cell>
          <mat-cell *matCellDef="let tariff">
            <p class="email text-truncate">
              {{ tariff.cost }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- collor Column -->
        <!-- <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Kleur</mat-header-cell>
          <mat-cell *matCellDef="let tariff">
            <div
              class="dot"
              [ngStyle]="{ 'background-color': '#' + tariff.color }"
            ></div>
            <p class="phone text-truncate">#{{ tariff.color }}</p>
          </mat-cell>
        </ng-container> -->

        <!-- button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let tariff">
            <!-- <div fxFlex="row" fxLayoutAlign="end center">

                            <button mat-icon-button [disabled]="!tariff.active" [matMenuTriggerFor]="moreMenu"
                                aria-label="More" (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <button mat-menu-item (click)="editVehicle(vehicle)">
                                    <span>Bewerken</span>
                                </button>
                                <button mat-menu-item (click)="deactivateVehicle(vehicle)">
                                    <span>Inactief</span>
                                </button>
                            </mat-menu>
                        </div> -->
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let tariff; columns: displayedColumns" class="user" (click)="editTariff(tariff)"
          matRipple></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <input hidden type="file" accept=".xlsx" #uploader (change)="uploadedFile($event)" />
</div>
<button mat-fab class="fab-fixed" (click)="newTariff()">
  <mat-icon>add</mat-icon>
</button>