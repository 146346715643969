import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { environment } from '../../../environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // video: string = environment.login.video;
  image: string = environment.login.image;
  name: string = environment.name.name;
  prefix: string = environment.phone.nl;
  loginMethodEmail = environment.loginMethod.email;

  email: string;
  password: string;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();
  errorLoginMessage: string;
  user: any;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    // console.log("Login methode => ", this.loginMethod)
  }

  // Login with Email
  async mailLogin() {
    (await this.afAuth.app)
      .auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .then(() => {
        this.router.navigate(['/planner'], {});
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/user-not-found' || 'auth/wrong-password') {
          this.errorLoginMessage =
            'De combinatie email en wachtwoord is niet correct';
        } else {
          this.errorLoginMessage =
            'Er is een fout opgetreden, probeer het later opnieuw';
        }
        this.password = '';
        console.log(errorCode, errorMessage);
        // this._snackBar.open('Email en/of wachtwoord is incorrect ingevuld', 'X', {
        //   duration: 5000,
        // });
        // ...
      });
  }
  async resetPassword() {
    if (!this.email) {
      this.errorLoginMessage =
        'Voer het mailadres van een account in om het wachtwoord te resetten.';
      return;
    }
    try {
      (await this.afAuth.app).auth().languageCode = 'nl';
      (await this.afAuth.app).auth().sendPasswordResetEmail(this.email);
      this.errorLoginMessage = '';
      this._snackBar.open(
        'Er wordt een wachtwoord reset link verstuurd indien er een account bekend is op het mailadres',
        'X',
        {
          duration: 5000,
        }
      );
    } catch (err) {
      this.errorLoginMessage =
        'Er is iets fout gegaan, probeer het later opnieuw.';
    }
  }
  async ngOnInit() {}
}
