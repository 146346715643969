<h1 mat-dialog-title *ngIf="!newUser">{{user.name}} <span>({{user.email}})</span></h1>
<div mat-dialog-content>
    <form fxLayout="column" (ngSubmit)="save()" [formGroup]="userForm">
        <mat-form-field *ngIf="newUser">
            <mat-label>E-mail</mat-label>
            <input formControlName="email" type="email" matInput required>
        </mat-form-field>
        <mat-form-field *ngIf="newUser">
            <mat-label>Wachtwoord</mat-label>
            <input formControlName="password" type="password" matInput required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Naam</mat-label>
            <input formControlName="name" matInput required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Account type</mat-label>
            <mat-select formControlName="accountType" required (selectionChange)="typeChanged()">
                <mat-option *ngIf="!newUser" style="font-style: italic;" [value]="'none'">Geen
                </mat-option>
                <mat-option [value]="'driver'">Chauffeur</mat-option>
                <mat-option [value]="'planner'">Planner</mat-option>
                <mat-option [value]="'admin'">Administrator</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="userForm.controls.defaultVehicleId">
            <mat-label>Voertuig voor deze chauffeur</mat-label>
            <mat-select formControlName="defaultVehicleId" required>
                <mat-option *ngFor="let vehicle of vehicles | async" [value]="vehicle.id">{{vehicle.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Annuleren</button>
    <button [class.spinner]="saving" mat-raised-button [disabled]="!userForm.valid" (click)="save()" cdkFocusInitial
        color="primary">Opslaan</button>
</div>