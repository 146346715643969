export const environment = {
  production: false,
  newUserLink: 'https://www.mijn.dewijksedienst.nl/#!/login?instaLogout=true',
  functionsUrl: 'https://us-central1-buurtmobiel-dev.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyC7lYb8Is02wNKiyKfQ4NtwRnbX_aw_WOE',
    authDomain: 'buurtmobiel-dev.firebaseapp.com',
    databaseURL: 'https://buurtmobiel-dev.firebaseio.com',
    projectId: 'buurtmobiel-dev',
    storageBucket: 'buurtmobiel-dev.appspot.com',
    messagingSenderId: '823187000049',
    appId: '1:823187000049:web:7947730fd461f705',
  },
  loginMethod: {
    phone: false,
    email: true,
  },
  startHour: 7,
  endHour: 18,
  login: {
    image:
      'https://firebasestorage.googleapis.com/v0/b/buurtvervoer-wijkbijduurstede.appspot.com/o/Afbeelding%20voor%20Login%20WIjkse%20dienst-03.svg?alt=media&token=6baed3a5-000d-4462-a241-dc8b9f4b5a0a',
  },
  name: {
    name: 'Buurtvervoer Development',
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  routes: [
    'login',
    'planner',
    'users',
    'adminUsers',
    'frequentAddress',
    'audit',
    'settings',
    'settingsVehicles',
    'settingsTariffs',
  ],
  showSendEmail: true,
};
