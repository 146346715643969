<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="h-title" fxLayoutAlign="start center" fxFlex>
      <mat-icon>assignment_turned_in</mat-icon>
      <span>Audits</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button style="margin-top: -10px" (click)="exportVisibleAudit()">
        <mat-icon style="margin-right: 4px">publish</mat-icon>Exporteren
      </button>
      <div class="search" fxFlex>
        <mat-form-field color="accent">
          <mat-label class="placeholder">Zoeken</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput [(ngModel)]="searchQuery" (ngModelChange)="onFilterChange('search', $event)" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-card *ngIf="history; else loading">
    <div class="title" fxLayout="row wrap" fxLayout.xs="column">
      <h3 fxFlex="20">
        Alle audits <span>({{ history.length }})</span>
      </h3>
      <div fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.gt-xs="end center" fxLayoutGap="4px">
        <mat-form-field>
          <mat-label>Selecteer filter</mat-label>
          <mat-select (selectionChange)="getAuditData()" [(ngModel)]="selectedFilter">
            <mat-option *ngFor="let filter of dateFilter" [value]="filter.value">{{ filter.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedFilter == 'year' || selectedFilter == 'month'">
          <mat-label>Jaar</mat-label>
          <mat-select (selectionChange)="getAuditData()" [(ngModel)]="selectedYear">
            <mat-option *ngFor="let year of years" [value]="year.value">{{ year.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedFilter == 'month'">
          <mat-label>Maand</mat-label>
          <mat-select (selectionChange)="getAuditData()" [(ngModel)]="selectedMonth">
            <mat-option *ngFor="let month of months" [value]="month.value">{{ month.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <button class="button-filter" mat-raised-button style="height: 51px;margin-bottom: 20px;"
                    (click)="getAuditData()">
                    <mat-icon>filter_list</mat-icon>Filter
                </button> -->
      </div>
    </div>
    <div #TABLE>
      <mat-table mat-table id="tblData" #table [dataSource]="$filteredHistory">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
          <mat-cell *matCellDef="let history">
            <p class="text-truncate" *ngIf="history.userData.name">
              {{ history.userData.name }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="credits">
          <mat-header-cell *matHeaderCellDef>Credits</mat-header-cell>
          <mat-cell *matCellDef="let history">
            <p *ngIf="history.creditsAdded" class="email text-truncate">
              <span class="green">+{{ history.creditsAdded }}</span>
            </p>
            <p *ngIf="history.creditsSpent" class="email text-truncate">
              <span class="red">-{{ history.creditsSpent }}</span>
            </p>
          </mat-cell>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
          <mat-cell *matCellDef="let history">
            <p class="phone text-truncate">
              {{ history.date.toDate() | date: 'd/MM/yyyy HH:mm' }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Beschrijving</mat-header-cell>
          <mat-cell *matCellDef="let history" fxHide fxShow.gt-md>
            <p class="phone text-truncate">
              {{ history.description }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="remarks">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Opmerkingen</mat-header-cell>
          <mat-cell *matCellDef="let history" fxHide fxShow.gt-md>
            <p class="phone text-truncate">
              {{ history.remarks }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actionBy">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>Behandelaar</mat-header-cell>
          <mat-cell *matCellDef="let history" fxHide fxShow.gt-xs>
            <p class="phone text-truncate">
              {{ history.actionBy }}
            </p>
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let user; columns: displayedColumns" class="user" matRipple></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>