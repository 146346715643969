<h1 mat-dialog-title>Rit kopiëren</h1>
<div mat-dialog-content>
    <form fxLayout="column" (ngSubmit)="copy()" [formGroup]="copyForm">
        <mat-form-field>
            <mat-label>Wekelijks of om de week</mat-label>
            <mat-select formControlName="type" required>
                <!-- <mat-option value="daily">Dagelijks</mat-option> -->
                <mat-option value="weekly">Wekelijks</mat-option>
                <mat-option value="everyotherweek">Om de week</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Aantal kopieën (13 maximaal)</mat-label>
            <input formControlName="times" type="number" min="1" max="13" matInput required>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Annuleren</button>
    <button mat-raised-button [disabled]="!copyForm.valid" (click)="copy()" color="primary">Kopieën maken</button>
</div>