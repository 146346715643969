import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { User } from '../../../interfaces';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  Product,
  ProductId,
} from 'src/app/planner/dialogs/edit-ride/dialogs/user-ride/user-ride.component';
import { firestore } from 'firebase';

export interface DialogData {
  user: any;
}

export interface Icon {
  name: string;
  description: string;
  icon: string;
  iconType: string;
  takesSeat: boolean;
  editableInRide: boolean;
}

export interface IconId extends Icon {
  id: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  userForm: FormGroup;
  iconsFormGroup: FormGroup;
  icons: Observable<IconId[]>;
  iconsCollection: AngularFirestoreCollection<Icon>;
  productCollection: AngularFirestoreCollection<Product>;
  products: Observable<ProductId[]>;
  productsArray: Array<object>;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserComponent>
  ) {}

  ngOnInit() {
    console.log('user', this.data.user);
    // check for white spaces in postal other wise save button is disabled
    // let birthdate = new Date();
    // if (this.data.user.birthdate) {
    //   birthdate = this.data.user.birthdate.toDate();
    // }
    this.iconsFormGroup = this.fb.group({});
    this.userForm = this.fb.group({
      name: [this.data.user.name, Validators.required],
      streetName: [this.data.user.streetName, Validators.required],
      houseNumber: [this.data.user.houseNumber, Validators.required],
      postal: [this.data.user.postal, Validators.required],
      city: [this.data.user.city, Validators.required],
      // birthdate: [birthdate, Validators.required],
      phoneNumber: [this.data.user.phoneNumber],
      cardId: [this.data.user.cardId],
      remarks: [this.data.user.remarks],
      commentsForDriver: [this.data.user.commentsForDriver],
      rideProductId: [
        this.data.user.rideProductId ? this.data.user.rideProductId : 'none',
      ],
      icons: this.iconsFormGroup,
      // rollator: [this.data.user.rollator],
      // guideDog: [this.data.user.guideDog],
      // cane: [this.data.user.cane],
      // escort: [this.data.user.escort],
      // companion: [this.data.user.companion],
      // wheelchair: [this.data.user.wheelchair],
      // freeUser: [this.data.user.freeUser],
    });
    // console.log('this.userForm', this.userForm);
    this.iconsCollection = this.db.collection<Icon>('icons', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.icons = this.iconsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Icon;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.icons.subscribe((icons) => {
      if (!this.data.user.icons) {
        this.data.user.icons = {};
      }
      icons.forEach((icon) => {
        this.iconsFormGroup.registerControl(
          icon.id,
          new FormControl(
            this.data.user.icons[icon.id]
              ? this.data.user.icons[icon.id]
              : false
          )
        );
      });
    });
    this.productCollection = this.db.collection<Product>('products', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.products = this.productCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Product;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.products.subscribe((products) => {
      // console.log('products', products);
      this.productsArray = products;
      if (this.data.user.rideProductId) {
        const selectedProduct = products.find((product) => {
          return product.id === this.data.user.rideProductId;
        });
        if (!selectedProduct) {
          this.userForm.patchValue({ rideProductId: 'none' });
        }
      }
    });
  }

  async save() {
    console.log('userForm', this.userForm);
    if (this.userForm.valid) {
      const saveObj = this.userForm.value as User;
      if (saveObj.postal && saveObj.postal.indexOf(' ') >= 0) {
        saveObj.postal = saveObj.postal.replace(/ /g, '');
      }
      Object.keys(saveObj).forEach((key) => {
        console.log('key', key);
        if (saveObj[key] == null) {
          delete saveObj[key];
        }
      });
      if (saveObj.rideProductId === 'none' || !saveObj.rideProductId) {
        saveObj.rideProductId = firestore.FieldValue.delete();
      }
      console.log('saveObj', saveObj);
      await this.db.collection('users').doc(this.data.user.id).update(saveObj);
      this.dialogRef.close(true);
    }
  }
}
