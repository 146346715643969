<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row" fxLayoutAlign=" center">
    <div class="h-title" fxFlex>
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
    </div>
    <!-- <div class="search">
            <mat-icon>search</mat-icon>
            <mat-form-field color="accent">
                <mat-label class="placeholder">Zoeken</mat-label>
                <input matInput [(ngModel)]="searchQuery" (ngModelChange)="onFilterChange('search', $event)">
            </mat-form-field>
        </div> -->
  </div>
  <mat-card style="padding: 24px">
    <!-- <div class="title" fxLayout="row">
            <h3 fxFlex>Alle audits <span>({{history.length}})</span></h3>
        </div> -->
    <form fxLayout="column" (ngSubmit)="save()" [formGroup]="organisationForm">
      <mat-form-field>
        <mat-label>Organisatie naam</mat-label>
        <input formControlName="name" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Maximum aantal voertuigen</mat-label>
        <input formControlName="maxActiveVehicles" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Pincode voor credits opboeken</mat-label>
        <input formControlName="pinAddCredits" #pin matInput minlength="4" maxlength="4" />
        <mat-error *ngIf="organisationForm.controls.pinAddCredits.hasError('minlength')">
          Pin moet 4 tekens zijn.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Pincode voor credits afboeken</mat-label>
        <input formControlName="pinSubstractCredits" #pin matInput minlength="4" maxlength="4" />
        <mat-error *ngIf="organisationForm.controls.pinSubstractCredits.hasError('minlength')">
          Pin moet 4 tekens zijn.
        </mat-error>
      </mat-form-field>
      <!-- <mat-form-field>
                <mat-label>Link naar gebruikersomgeving</mat-label>
                <input formControlName="link" matInput required>
            </mat-form-field> -->
      <!-- <div fxLayoutGap="16px">
                <mat-form-field fxFlex="50">
                    <mat-label>Hoofdkleur</mat-label>
                    <input matInput placeholder="#FFFFFF" readonly="true" formControlName="primaryColor" [(colorPicker)]="organisationForm.value.primaryColor"
                        [value]="organisationForm.value.primaryColor" #ignoredInput [cpIgnoredElements]="[ignoredButton, ignoredInput]"
                        [(cpToggle)]="togglePrimary" [cpAlphaChannel]="'disabled'"
                        [cpOKButton]="true" [cpPosition]="'top'" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'mat-stroked-button'" [cpOKButtonClass]="'mat-stroked-button'"
                        [cpCancelButtonText]="'Annuleren'" />
                    <button matSuffix mat-icon-button #ignoredButton type="button"
                        (click)="togglePrimary=!togglePrimary">
                        <mat-icon>colorize</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <mat-label>Accentkleur</mat-label>
                    <input matInput placeholder="#FFFFFF" readonly="true" formControlName="accentColor" [(colorPicker)]="organisationForm.value.accentColor"
                        [value]="organisationForm.value.accentColor" #ignoredInput [cpIgnoredElements]="[ignoredButton, ignoredInput]"
                        [(cpToggle)]="toggleAccent" [cpAlphaChannel]="'disabled'"
                        [cpOKButton]="true" [cpPosition]="'top'" [cpCancelButton]="true"
                        [cpCancelButtonClass]="'mat-stroked-button'" [cpOKButtonClass]="'mat-stroked-button'"
                        [cpCancelButtonText]="'Annuleren'" />
                    <button matSuffix mat-icon-button #ignoredButton type="button" (click)="toggleAccent=!toggleAccent">
                        <mat-icon>colorize</mat-icon>
                    </button>
                </mat-form-field>
            </div> -->
    </form>
    <div fxLayoutAlign="end center">
      <!-- <button mat-button [mat-dialog-close]="false">Annuleren</button> -->
      <button mat-raised-button [disabled]="!organisationForm.valid" (click)="save()" cdkFocusInitial color="accent">
        Opslaan
      </button>
    </div>
  </mat-card>
  <!-- <ng-template #loading>
        <div id="loading" fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template> -->
</div>