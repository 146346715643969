import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  type: string;
  user: any;
}

@Component({
  selector: 'app-change-hidden-status',
  templateUrl: './change-hidden-status.component.html',
  styleUrls: ['./change-hidden-status.component.scss']
})
export class ChangeHiddenStatusComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    console.log('data', this.data);
  }

}
