import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-change',
  templateUrl: './confirm-change.component.html',
  styleUrls: ['./confirm-change.component.scss']
})
export class ConfirmChangeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
