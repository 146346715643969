<div>
    <form fxLayout="column" (ngSubmit)="onSubmit()" [formGroup]="vehicleForm">
        <mat-form-field>
            <mat-label>Voertuignaam</mat-label>
            <input placeholder="Voertuignaam" formControlName="name" matInput required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Aantal zitplaatsen</mat-label>
            <input type="number" placeholder="Zitplaatsen" formControlName="seats" matInput required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Agenda kleur</mat-label>
            <input matInput placeholder="#FFFFFF" readonly="true" formControlName="color" [value]="color" required
                #ignoredInput [cpIgnoredElements]="[ignoredButton, ignoredInput]" [(cpToggle)]="toggle"
                [(colorPicker)]="color" [cpAlphaChannel]="'disabled'" [cpOKButton]="true" [cpPosition]="'top'"
                [cpCancelButton]="true" [cpCancelButtonClass]="'mat-stroked-button'"
                [cpOKButtonClass]="'mat-stroked-button'" [cpCancelButtonText]="'Annuleren'" />
            <button matSuffix mat-icon-button #ignoredButton type="button" (click)="toggle=!toggle">
                <mat-icon>colorize</mat-icon>
            </button>
        </mat-form-field>
        <div *ngIf="setting | async as setting">
            <mat-slide-toggle [disabled]="totalActive >= setting.maxActiveVehicles && !isActive"
                formControlName="active">
                Actief
            </mat-slide-toggle>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="6px">
            <button mat-flat-button type="button" (click)="close()">Annuleren</button>
            <button mat-flat-button type="submit" [disabled]="vehicleForm.invalid">Opslaan</button>
        </div>
    </form>
</div>