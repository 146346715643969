<h1 *ngIf="!this.data.delete" mat-dialog-title>Reeks updaten</h1>
<h1 *ngIf="this.data.delete" mat-dialog-title>Reeks verwijderen</h1>
<mat-dialog-content>
    <div *ngIf="!this.data.delete">
        <h3>Wil je alle toekomstige ritten in deze reeks updaten?</h3>
        Wanneer je alleen deze rit bewerkt, zal deze niet langer onderdeel zijn van de reeks.
    </div>
    <div *ngIf="this.data.delete">
        <h3>Wil je alle toekomstige ritten in deze reeks verwijderen?</h3>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]>Annuleren</button>
    <button mat-button [mat-dialog-close]="false">Alleen deze rit</button>
    <button *ngIf="!this.data.delete" cdkFocusInitial mat-raised-button (click)="updateCopies()" color="primary">Alles
        updaten</button>
    <button *ngIf="this.data.delete" cdkFocusInitial mat-raised-button (click)="deleteCopies()" color="primary">Alles
        verwijderen</button>
</mat-dialog-actions>