import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Organisation } from '../interfaces';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  organisationForm: any;
  organisationDoc: AngularFirestoreDocument<Organisation>;
  organisation: Observable<Organisation>;

  togglePrimary: boolean;
  toggleAccent: boolean;

  constructor(
    public db: AngularFirestore,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.organisationForm = this.fb.group({
      name: [''],
      maxActiveVehicles: [{ value: '', disabled: true }],
      // link: ['', Validators.required],
      primaryColor: ['#FFFFFF'],
      accentColor: ['#FFFFFF'],
      pinAddCredits: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
      pinSubstractCredits: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
    });
    this.organisationDoc = this.db.doc<Organisation>('settings/general');
    this.organisation = this.organisationDoc.valueChanges();
    this.organisation.subscribe((organisation) => {
      console.log('this.organisation', organisation);
      this.organisationForm.patchValue(organisation);
    });
  }

  async save() {
    console.log('organisationForm', this.organisationForm);
    if (this.organisationForm.valid) {
      const saveObj = this.organisationForm.value;
      Object.keys(saveObj).forEach((key) => {
        console.log('key', key);
        if (saveObj[key] == null) {
          delete saveObj[key];
        }
      });
      console.log('saveObj', saveObj);
      try {
        await this.organisationDoc.set(saveObj, { merge: true });
        this._snackBar.open('Organisatie aangepast', '', {
          duration: 2000,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
