<h1 mat-dialog-title fxLayout="row">
    <span class="title" fxFlex *ngIf="data.ride.title">{{data.ride.title}}</span>
    <span class="title" fxFlex *ngIf="!data.ride.title">{{data.ride.availableSeats}} vrij</span>
    <div class="buttons">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button (click)="editRide()" matTooltip="Rit aanpassen">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="copyRide()" matTooltip="Rit kopiëren">
            <mat-icon>file_copy</mat-icon>
        </button>
    </div>
</h1>
<div mat-dialog-content>
    <div class="info" *ngIf="data.ride.comments">
        <h4>Opmerkingen</h4>
        <p>{{data.ride.comments}}</p>
    </div>

    <div class="info" *ngIf="data.vehicle">
        <h4>Voertuig</h4>
        <p>{{data.vehicle.name}}</p>
    </div>

    <div class="info">
        <h4>Begintijd</h4>
        <p>{{data.ride.start | date: 'd/MM HH:mm'}} - {{data.ride.end | date: 'd/MM HH:mm'}}</p>
    </div>

    <div class="info" *ngIf="data.ride.rideProduct">
        <h4>Ritprijs</h4>
        <p>{{data.ride.rideProduct['name']}} - {{data.ride.rideProduct['cost']}} credits</p>
    </div>

    <div class="info" *ngIf="data.ride.users">
        <h4>Ophalen</h4>
        <div class="user" *ngFor="let user of users; let i=index">
            <div class="name">{{i+1}}. {{user.title}}</div>
            <div class="icons" fxLayout="row">
                <div class="icon" [ngClass]="{'active': user[icon.id] === true}" [matTooltip]="icon.name"
                    *ngFor="let icon of icons | async">
                    <mat-icon *ngIf="icon.iconType == 'material'">
                        {{icon.icon}}</mat-icon>
                    <div class="mat-icon" *ngIf="icon.iconType == 'custom'"
                        [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"></div>
                </div>
            </div>
            <div class="location">
                <a *ngIf="user.from.address else normalAddress" href="http://maps.google.com/?q={{user.from.address}}"
                    target="_blank">
                    <mat-icon>navigation</mat-icon>{{user.from.name}} - {{user.from.address}}
                </a>
                <ng-template #normalAddress>
                    <a href="http://maps.google.com/?q={{user.from}}" target="_blank">
                        <mat-icon>navigation</mat-icon>{{user.from}}
                    </a>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="info" *ngIf="data.ride.users">
        <h4>Bestemming</h4>
        <div class="user" *ngFor="let user of users; let i=index">
            <div class="name">{{i+1}}. {{user.title}}</div>
            <div class="icons" fxLayout="row">
                <div class="icon" [ngClass]="{'active': user[icon.id] === true}" [matTooltip]="icon.name"
                    *ngFor="let icon of icons | async">
                    <mat-icon *ngIf="icon.iconType == 'material'">
                        {{icon.icon}}</mat-icon>
                    <div class="mat-icon" *ngIf="icon.iconType == 'custom'"
                        [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"></div>
                </div>
            </div>
            <div class="location">
                <a *ngIf="user.to.address else normalAddress" href="http://maps.google.com/?q={{user.to.address}}"
                    target="_blank">
                    <mat-icon>navigation</mat-icon>{{user.to.name}} - {{user.to.address}}
                </a>
                <ng-template #normalAddress>
                    <a href="http://maps.google.com/?q={{user.to}}" target="_blank">
                        <mat-icon>navigation</mat-icon>{{user.to}}
                    </a>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<!-- <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Nee</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
</div> -->