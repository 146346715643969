import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Ride, Vehicle, VehicleId } from '../../planner.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserRideComponent } from './dialogs/user-ride/user-ride.component';
import { CopyUpdateComponent } from '../../dialogs/copy-update/copy-update.component';
import { DeleteRideComponent } from './dialogs/delete-ride/delete-ride.component';
import { IconId, Icon } from 'src/app/users/dialogs/user/user.component';
import { getWeekYear, getWeek } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface DialogData {
  ride: Ride;
  defaultVehicle: string;
  dates: {
    start: Date;
    end: Date;
  };
}

export interface Product {
  id: string;
  name: string;
  default: boolean;
  cost: number;
  color: string;
}
export interface ProductId extends Product {
  id: string;
}

@Component({
  selector: 'app-edit-ride',
  templateUrl: './edit-ride.component.html',
  styleUrls: ['./edit-ride.component.scss'],
})
export class EditRideComponent implements OnInit {
  rideId: string;
  newRide: boolean;
  rideForm: FormGroup;
  users: Array<any>;
  saveObj: Map<string, any>;
  hours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  minutes = [
    { val: 0, text: '00' },
    { val: 5, text: '05' },
    { val: 10, text: '10' },
    { val: 15, text: '15' },
    { val: 20, text: '20' },
    { val: 25, text: '25' },
    { val: 30, text: '30' },
    { val: 35, text: '35' },
    { val: 40, text: '40' },
    { val: 45, text: '45' },
    { val: 50, text: '50' },
    { val: 55, text: '55' },
  ];
  productCollection: AngularFirestoreCollection<Product>;
  products: Observable<ProductId[]>;
  productsArray: Array<object>;
  vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<VehicleId[]>;
  iconsCollection: AngularFirestoreCollection<Icon>;
  icons: Observable<IconId[]>;
  iconsArray: Array<IconId>;
  vehiclesArray: Array<object>;
  selectedVehicle: any;
  availableSeats: number;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditRideComponent>,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    console.log('ride', this.data.ride);
    if (this.data.ride.id) {
      this.rideId = this.data.ride.id;
    } else {
      this.newRide = true;
      this.rideId = this.db.createId();
    }
    // this.users = [];
    if (this.data.ride.users) {
      Object.keys(this.data.ride.users).forEach((key) => {
        this.data.ride.users[key].id = key;
      });
      this.users = Object.values(this.data.ride.users).sort((x, y) => {
        return x.order - y.order;
      });
    } else {
      this.users = [];
    }
    console.log('users', this.users);
    let start;
    // new dates are created so the changed form value is not linked to input data object anymore, so we can calculate the offset.
    if (this.data.ride.start) {
      start = new Date(this.data.ride.start);
    } else {
      start = new Date(this.data.dates.start);
    }
    let end;
    if (this.data.ride.end) {
      end = new Date(this.data.ride.end);
    } else {
      end = new Date(this.data.dates.end);
    }
    let rideProduct = {};
    if (this.data.ride.rideProduct) {
      rideProduct = this.data.ride.rideProduct;
    }
    this.rideForm = this.fb.group({
      comments: [this.data.ride.comments],
      start: [start, Validators.required],
      startHour: [start.getHours(), Validators.required],
      startMinute: [start.getMinutes(), Validators.required],
      end: [end, Validators.required],
      endHour: [end.getHours(), Validators.required],
      endMinute: [end.getMinutes(), Validators.required],
      rideProduct: [, Validators.required],
      vehicleId: [, Validators.required],
    });

    this.productCollection = this.db.collection<Product>('products', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.products = this.productCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Product;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.products.subscribe((products) => {
      // console.log('products', products);
      this.productsArray = products;
      if (this.data.ride.rideProduct) {
        const selectedProduct = products.find((product) => {
          return product.id === this.data.ride.rideProduct.id;
        });
        this.rideForm.patchValue({ rideProduct: selectedProduct.id });
      } else {
        const defaultProduct = products.find((product) => {
          return product.default;
        });
        this.rideForm.patchValue({ rideProduct: defaultProduct.id });
      }
    });

    this.vehicleCollection = this.db.collection<Vehicle>('vehicles', (ref) =>
      ref.orderBy('name', 'asc').where('active', '==', true)
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.iconsCollection = this.db.collection<Icon>('icons', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.icons = this.iconsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Icon;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.icons.subscribe((icons) => {
      this.iconsArray = icons;
    });
    this.vehicles.subscribe((vehicles) => {
      // console.log('vehicles', vehicles);
      this.vehiclesArray = vehicles;
      if (this.data.ride.vehicleId) {
        this.selectedVehicle = vehicles.find((vehicle) => {
          return vehicle.id === this.data.ride.vehicleRef.id;
        });
      } else if (this.data.defaultVehicle !== 'all') {
        this.selectedVehicle = vehicles.find((vehicle) => {
          return vehicle.id === this.data.defaultVehicle;
        });
      }
      if (!this.selectedVehicle) {
        // IF SELECTEDVEHICLE IS STILL UNDEFINED USE DEFAULT
        this.selectedVehicle = vehicles[0];
      }
      this.rideForm.patchValue({ vehicleId: this.selectedVehicle.id });
      this.updateAvailableSeats();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.users, event.previousIndex, event.currentIndex);
  }

  dateUpdated(type) {
    const patchObj = {};
    if (type === 'start') {
      const startDate = new Date(0);
      startDate.setTime(
        this.rideForm.value.start.setHours(
          this.rideForm.value.startHour,
          this.rideForm.value.startMinute
        )
      );
      if (startDate >= this.rideForm.value.end) {
        const endDate = new Date(0);
        endDate.setTime(
          this.rideForm.value.start.setHours(
            this.rideForm.value.startHour,
            this.rideForm.value.startMinute + 15
          )
        );
        patchObj['end'] = endDate;
        patchObj['endHour'] = endDate.getHours();
        patchObj['endMinute'] = endDate.getMinutes();
        this._snackBar.open(
          'De einddatum is veranderd omdat deze eerder was dan de begindatum.',
          'X',
          {
            duration: 3000,
          }
        );
      }
      patchObj['start'] = startDate;
    } else if (type === 'end') {
      const endDate = new Date(0);
      endDate.setTime(
        this.rideForm.value.end.setHours(
          this.rideForm.value.endHour,
          this.rideForm.value.endMinute
        )
      );
      patchObj['end'] = endDate;
      if (this.rideForm.value.start >= endDate) {
        const endDate = new Date(0);
        const startDate = new Date(this.rideForm.value.start);
        endDate.setTime(
          startDate.setHours(
            this.rideForm.value.startHour,
            this.rideForm.value.startMinute + 15
          )
        );
        patchObj['end'] = endDate;
        patchObj['endHour'] = endDate.getHours();
        patchObj['endMinute'] = endDate.getMinutes();
        this._snackBar.open(
          'De gekozen einddatum was eerder dan de begindatum daarom is hij veranderd.',
          'X',
          {
            duration: 3000,
          }
        );
      }
    }
    this.rideForm.patchValue(patchObj);
    console.log('startDate', this.rideForm.value.start);
    console.log('endDate', this.rideForm.value.end);
  }
  moveUser(direction, i, user) {
    let newIndex = i;
    if (direction === 'up') {
      newIndex = newIndex - 1;
    }
    if (direction === 'down') {
      newIndex = newIndex + 1;
    }
    moveItemInArray(this.users, i, newIndex);
  }

  vehicleChanged() {
    this.selectedVehicle = this.vehiclesArray.find((vehicle) => {
      return vehicle['id'] === this.rideForm.value.vehicleId;
    });
    this.updateAvailableSeats();
    console.log('this.selectedVehicle', this.selectedVehicle);
  }

  updateAvailableSeats() {
    const totalSeats = this.selectedVehicle.seats;
    let seatsTaken = 0;
    this.users.forEach((user) => {
      console.log('user', user);
      seatsTaken = seatsTaken + 1;
      this.iconsArray.forEach((icon) => {
        if (user[icon.id] && icon.takesSeat) {
          seatsTaken = seatsTaken + 1;
        }
      });
    });
    this.availableSeats = totalSeats - seatsTaken;
  }

  changeUser(i?, user?) {
    let newUser = false;
    if (!user) {
      user = {};
      newUser = true;
    }
    const dialogRef = this.dialog.open(UserRideComponent, {
      width: '600px',
      data: { user, icons: this.iconsArray },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result: ', result);
      if (result) {
        if (newUser) {
          this.users.push(result);
        } else {
          this.users[i] = result;
        }
        this.updateAvailableSeats();
      }
    });
  }
  removeUser(index) {
    this.users.splice(index, 1);
    this.updateAvailableSeats();
  }

  async deleteRide() {
    const deleteRef = this.dialog.open(DeleteRideComponent, {
      width: '300px',
    });
    deleteRef.afterClosed().subscribe(async (deleteResult) => {
      console.log('The dialog was closed');
      if (deleteResult) {
        /////////////
        if (this.data.ride.parentRideId) {
          // ride is part of group, ask if users wants to edit all
          // if yes, override all data in future rides (after recalculating the date)
          // all updated rides should get this new parentRideId so they are unlinked from past events

          const updateRef = this.dialog.open(CopyUpdateComponent, {
            width: '600px',
            data: {
              oldRide: this.data.ride,
              delete: true,
            },
          });
          updateRef.afterClosed().subscribe(async (result) => {
            console.log('The dialog was closed with result: ', result);
            if (result) {
              // sucessfully updated copies (or an error!)
              await this.db.collection('rides').doc(this.rideId).delete();
              this._snackBar.open('De ritten zijn verwijderd.', 'X', {
                duration: 5000,
              });
              this.dialogRef.close();
            } else if (result === false) {
              // result false / user said 'no'
              // if no, remove parentRideId from this ride, then continue saving.
              await this.db.collection('rides').doc(this.rideId).delete();
              this._snackBar.open('De rit is verwijderd.', 'X', {
                duration: 5000,
              });
              this.dialogRef.close();
            }
            // result undefined / user cancelled dialog, do nothing, user can press save again if they want to.
          });
        } else {
          await this.db.collection('rides').doc(this.rideId).delete();
          this._snackBar.open('De rit is verwijderd.', 'X', {
            duration: 5000,
          });
          this.dialogRef.close();
        }
      }
    });
  }

  async save() {
    const saveObj = {};
    console.log('rideForm', this.rideForm.value);
    console.log('this.rideId', this.rideId);
    console.log('this.users', this.users);
    const usersObj = {};
    this.updateAvailableSeats();
    saveObj['title'] =
      this.availableSeats === 0 ? 'VOL' : `${this.availableSeats} vrij`;
    if (this.data.ride.users) {
      Object.keys(this.data.ride.users).forEach((id) => {
        usersObj[id] = firestore.FieldValue.delete();
      });
    }
    // if (this.users.length !== 0) { saveObj['title'] }
    if (this.rideForm.value.comments) {
      saveObj['title'] = saveObj['title'] + ', ' + this.rideForm.value.comments;
    }
    this.users.forEach((user, i) => {
      user.order = i;
      if (!user.rideProduct) {
        user.rideProduct = firestore.FieldValue.delete();
      }
      usersObj[user.id] = user;
      if (i === 0) {
        saveObj['fromLocation'] = user.from;
      }
      if (i === this.users.length - 1) {
        saveObj['toLocation'] = user.to;
      }
      saveObj['title'] = saveObj['title'] + ', ' + user.title;
    });
    console.log('this.users', this.users);
    saveObj['start'] = this.rideForm.value.start;
    saveObj['end'] = this.rideForm.value.end;
    saveObj['comments'] = this.rideForm.value.comments;
    const selectedProduct = this.productsArray.find((product) => {
      return product['id'] === this.rideForm.value.rideProduct;
    });
    saveObj['rideProduct'] = selectedProduct;
    saveObj['vehicleId'] = this.rideForm.value.vehicleId;
    saveObj['vehicleRef'] = this.vehicleCollection.doc(
      this.rideForm.value.vehicleId
    ).ref;
    saveObj['availableSeats'] = this.availableSeats;

    if (this.users.length !== 0) {
      saveObj['users'] = usersObj;
    } else {
      saveObj['users'] = firestore.FieldValue.delete();
      saveObj['fromLocation'] = firestore.FieldValue.delete();
      saveObj['toLocation'] = firestore.FieldValue.delete();
    }
    console.log('this.data.ride.start', this.data.ride.start);
    console.log('saveObj.start', saveObj['start']);
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });

    if (saveObj['start']) {
      saveObj['year'] = saveObj['start'].getFullYear();
      saveObj['month'] = saveObj['start'].getMonth();
      saveObj['week'] = getWeekString(saveObj['start']);
      saveObj['day'] = saveObj['start'].getDate();
    }

    if (this.data.ride.parentRideId) {
      // ride is part of group, ask if users wants to edit all
      // if yes, override all data in future rides (after recalculating the date)
      // all updated rides should get this new parentRideId so they are unlinked from past events
      saveObj['parentRideId'] = this.data.ride.id;

      const dialogRef = this.dialog.open(CopyUpdateComponent, {
        width: '600px',
        data: {
          oldRide: this.data.ride,
          newRide: saveObj,
        },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('The dialog was closed with result: ', result);
        if (result) {
          // sucessfully updated copies (or an error!)
          await this.db
            .collection('rides')
            .doc(this.rideId)
            .set(saveObj, { merge: true });
          this.dialogRef.close();
        } else if (result === false) {
          // result false / user said 'no'
          // if no, remove parentRideId from this ride, then continue saving.
          saveObj['parentRideId'] = firestore.FieldValue.delete();
          await this.db
            .collection('rides')
            .doc(this.rideId)
            .set(saveObj, { merge: true });
          this.dialogRef.close();
        }
        // result undefined / user cancelled dialog, do nothing, user can press save again if they want to.
      });
    } else {
      await this.db
        .collection('rides')
        .doc(this.rideId)
        .set(saveObj, { merge: true });
      this.dialogRef.close();
    }
    console.log('saveObj', saveObj);
  }
}

function getWeekString(date) {
  return (
    getWeekYear(date, {
      weekStartsOn: 1,
    }) +
    '-' +
    getWeek(date, {
      weekStartsOn: 1,
    })
  );
}
