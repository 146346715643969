import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

export interface DialogData {
  type: string;
  user: any;
}

@Component({
  selector: 'app-change-credits',
  templateUrl: './change-credits.component.html',
  styleUrls: ['./change-credits.component.scss'],
})
export class ChangeCreditsComponent implements OnInit {
  creditForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangeCreditsComponent>
  ) {}

  ngOnInit() {
    this.creditForm = this.fb.group({
      credits: [, [Validators.required, Validators.min(0.5)]],
      pin: [
        ,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
      remarks: [],
    });
    console.log('data', this.data);
  }
  save() {
    if (this.creditForm.valid) {
      this.dialogRef.close(this.creditForm.value);
    }
  }
}
