<!-- Background video should be dynamic, must come from DB -->

<div class="login-wrapper" fxLayout="row">
  <!-- <div fxLayout="row"> -->
  <div class="login-intro" fxFlex="65" fxHide.xs>
    <!-- <video width="1920" height="1280" autoplay muted loop id="background-video" preload="auto">
            <source src="{{video}}" type="video/mp4">
          </video> -->
    <div class="banner">
      <img [src]="image" />
      <div class="title">
        <!-- Welkom bij Bob de Bot! -->
      </div>
    </div>
  </div>
  <div class="login-form-wrapper" fxFlex>
    <mat-card
      class="login-form"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="login-logo">
        <img class="logo" />
      </div>

      <div class="title">Log nu in bij {{ name }}!</div>

      <div class="email-login" fxLayout="column" fxLayoutAlign="center center">
        <mat-error class="full-width" *ngIf="errorLoginMessage">
          {{ errorLoginMessage }}
        </mat-error>
        <mat-form-field class="full-width">
          <mat-label>E-mail</mat-label>
          <input
            matInput
            [(ngModel)]="email"
            [formControl]="emailFormControl"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            *ngIf="
              emailFormControl.hasError('email') &&
              !emailFormControl.hasError('required')
            "
          >
            Vul een geldig e-mailadres in.
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Je moet je e-mail invullen.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Wachtwoord</mat-label>
          <input matInput type="password" [(ngModel)]="password" />
        </mat-form-field>
        <button
          mat-raised-button
          [disabled]="!emailFormControl.valid || !password"
          color="primary"
          class="login-button"
          (click)="mailLogin()"
        >
          Login
        </button>
        <a href="javascript:void(0);" (click)="resetPassword()"
          >Wachtwoord vergeten?</a
        >
      </div>
    </mat-card>
  </div>
  <!-- </div> -->
</div>
