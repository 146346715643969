import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface Address {
  name: string;
  address: string;
}

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {
  addressForm: FormGroup;
  newAddress: boolean; // check if the address is being eddited or added.
  addressName: string;
  address: string; // address that the user fills in the form.
  addressId: string;
  // addressCollection: AngularFirestoreCollection<Address>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public db: AngularFirestore,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditAddressComponent>
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    this.newAddress = this.data.newAddress;
    if (this.newAddress === true) {
      this.addressForm = this.fb.group({
        name: ['', Validators.required],
        address: ['', Validators.required],
      });
    } else {
      this.addressId = this.data.address.id;
      this.addressName = this.data.address.name;
      this.address = this.data.address.address;
      this.addressForm = this.fb.group({
        name: [this.addressName, Validators.required],
        address: [this.address, Validators.required],
      });
    }
  }
  async save() {
    console.log('save address', this.addressForm.value);
    // const addressesDoc = this.db.doc('addresses/');
    if (!this.newAddress) {
      await this.db
        .doc(`addresses/${this.addressId}`)
        .update(this.addressForm.value);
      this._snackBar.open('Adres bijgewerkt!', 'X', {
        duration: 5000,
      });
    } else {
      await this.db.collection('addresses/').add(this.addressForm.value);
      this._snackBar.open('Adres toegevoegd!', 'X', {
        duration: 5000,
      });
    }
    this.dialogRef.close();
  }
}
