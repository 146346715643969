<h1 mat-dialog-title *ngIf="data.type == 'add'">
  Hoeveel credits wil je toevoegen?
</h1>
<h1 mat-dialog-title *ngIf="data.type == 'remove'">
  Hoeveel credits wil je verminderen?
</h1>
<div mat-dialog-content>
  <form [formGroup]="creditForm">
    <mat-form-field>
      <mat-label>Credits</mat-label>
      <input
        matInput
        type="number"
        formControlName="credits"
        required
        cdkFocusInitial
      />
    </mat-form-field>
    <mat-form-field class="pin">
      <mat-label>Pincode</mat-label>
      <input
        matInput
        formControlName="pin"
        type="password"
        required
        minlength="4"
        maxlength="4"
        #pin
      />
      <mat-hint align="end">{{ pin.value.length }} / 4</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Opmerkingen</mat-label>
      <input matInput formControlName="remarks" cdkFocusInitial />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Annuleren</button>
  <button
    mat-raised-button
    [disabled]="!creditForm.valid"
    (click)="save()"
    *ngIf="data.type == 'add'"
    color="primary"
  >
    Toevoegen
  </button>
  <button
    mat-raised-button
    [disabled]="!creditForm.valid"
    (click)="save()"
    *ngIf="data.type == 'remove'"
    color="warn"
  >
    Verminderen
  </button>
</div>
