import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Ride } from '../../planner.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Product } from '../edit-ride/edit-ride.component';

export interface DialogData {
  ride: Ride;
}

@Component({
  selector: 'app-copy-ride',
  templateUrl: './copy-ride.component.html',
  styleUrls: ['./copy-ride.component.scss']
})
export class CopyRideComponent implements OnInit {
  copyForm: FormGroup;
  copyInProgress: boolean;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public dialog: MatDialog,
    public dialogRef: MatDialogRef<CopyRideComponent>, private fb: FormBuilder) { }

  ngOnInit() {
    this.copyInProgress = false;
    console.log('ride', this.data.ride);
    this.copyForm = this.fb.group({
      type: ['weekly', Validators.required],
      times: [1, [Validators.required, Validators.max(13)]],
    });
  }

  async copy() {
    if (!this.copyInProgress) {
      this.copyInProgress = true;
      let i;
      const batch = this.db.firestore.batch();
      batch.update(this.db.collection('rides').doc(this.data.ride.id).ref, { parentRideId: this.data.ride.id })
      console.log('copy amount', this.copyForm.value.times);
      const products = (await this.db.firestore.collection('products').get()).docs;
      console.log('products', products);

      for (i = 0; i < this.copyForm.value.times; i++) {
        console.log('i', i);
        const newRideRef = this.db.collection('rides').doc(this.db.createId());
        // const ride = Object.assign({}, this.data.ride);
        const ride = { ...this.data.ride };
        const rideStartDate = ride.start;
        const rideEndDate = ride.end;
        const rideStartDay = rideStartDate.getDate();
        const rideEndDay = rideEndDate.getDate();
        if (this.copyForm.value.type === 'daily') {
          const startDate = rideStartDate.setDate(rideStartDay + 1);
          const endDate = rideEndDate.setDate(rideEndDay + 1);
          ride.start = new Date(startDate);
          ride.end = new Date(endDate);
          // ride.start = rideStartDate.setDate(rideStartDay + i).toDate();
        } else if (this.copyForm.value.type === 'weekly') {
          const startDate = rideStartDate.setDate(rideStartDay + 7);
          const endDate = rideEndDate.setDate(rideEndDay + 7);
          ride.start = new Date(startDate);
          ride.end = new Date(endDate);
        } else if (this.copyForm.value.type === 'everyotherweek') {
          const startDate = rideStartDate.setDate(rideStartDay + 14);
          const endDate = rideEndDate.setDate(rideEndDay + 14);
          ride.start = new Date(startDate);
          ride.end = new Date(endDate);
        }

        // Make sure copied rides have the correct and updated products in them, not the old values :)
        if (ride.rideProduct) {
          const selectedProduct = products.find(product => {
            return (product.id === ride.rideProduct.id);
          });
          if (selectedProduct) {
            ride.rideProduct = selectedProduct.data() as Product;
          }
        }
        for (const [userId, user] of Object.entries(ride.users)) {
          if (user.rideProduct) {
            const selectedProduct = products.find(product => {
              return (product.id === user.rideProduct.id);
            });
            if (selectedProduct) {
              user.rideProduct = selectedProduct.data() as Product;
            }
          }
          delete user.paid;
          delete user.charge;
        }
        console.log('ride', ride);
        ride['parentRideId'] = this.data.ride.id;
        delete ride['id'];
        batch.set(newRideRef.ref, ride);
      }
      console.log('batch', batch);
      await batch.commit();
      this.dialogRef.close();
    }
  }
}
