import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import { UserComponent } from './dialogs/user/user.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreditsHistoryComponent } from './dialogs/credits-history/credits-history.component';
import { ChangeHiddenStatusComponent } from './dialogs/change-hidden-status/change-hidden-status.component';
import { firestore } from 'firebase';
import { ChangeCreditsComponent } from './dialogs/change-credits/change-credits.component';
import { DeleteUserComponent } from './dialogs/delete-user/delete-user.component';
import { RideDetailsComponent } from '../planner/dialogs/ride-details/ride-details.component';
import * as XLSX from 'xlsx';
import { element } from 'protractor';
import { el } from 'date-fns/locale';
import { environment } from 'src/environments/environment';
import { Organisation, User, UserId } from '../interfaces';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  itemsToShow = 20;
  env = environment;
  allUsers: Observable<UserId[]>;
  // filteredUsers: Observable<UserId[]>;
  public filteredUsers: ReplaySubject<UserId[]> = new ReplaySubject<UserId[]>(
    1
  );
  usersCollection: AngularFirestoreCollection<User>;
  displayedColumns = [
    'name',
    'email',
    'phone',
    'registrationDate',
    'cardId',
    'buttons',
  ];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  showHidden = false;
  showHiddenChanged: Subject<boolean> = new Subject<boolean>();
  orderBy = 'name';
  orderByChanged: Subject<string> = new Subject<string>();
  orderDirection = 'asc';
  orderDirectionChanged: Subject<string> = new Subject<string>();

  totalUsers: number;
  plannerEmail: string;
  allUsersExcel: any = [];
  // Used for the delete button
  storeData: any;
  fileUploaded: File;
  worksheet: any;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.plannerEmail = user.email;
      }
    });
    this.usersCollection = this.db.collection<User>('users/');
    this.allUsers = this.usersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as object;
          if (data['createdAt'] !== undefined) {
            data['createdAt'] = data['createdAt'].toDate();
          }
          const userData = data as User;
          if (!userData.credits) {
            userData.credits = 0;
          }
          const id = a.payload.doc.id;
          return { id, ...userData };
        })
      )
    );
    const combinedObservable = combineLatest(
      this.allUsers,
      this.searchQueryChanged,
      this.showHiddenChanged,
      this.orderByChanged,
      this.orderDirectionChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const users = values[0];
        const searchQuery = values[1];
        const filters = {
          showHidden: values[2],
          orderBy: values[3],
          orderDirection: values[4],
        };
        let filteredUsers = users.filter((item) =>
          this.checkFilters(item, filters)
        );
        // this.orderDirection = 'desc';
        console.log('orderBy', filters.orderBy);
        filteredUsers = filteredUsers.sort((a, b) => {
          if (a[filters.orderBy] > b[filters.orderBy]) {
            return 1;
          } else {
            return -1;
          }
          // return 0;
        });
        if (filters.orderDirection === 'desc') {
          filteredUsers.reverse();
        }
        console.log('filteredUsers', filteredUsers);
        this.totalUsers = filteredUsers.length;
        this.filteredUsers.next(filteredUsers);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQueryChanged.next('');
    this.showHiddenChanged.next(this.showHidden);
    this.orderByChanged.next(this.orderBy);
    this.orderDirectionChanged.next(this.orderDirection);

    this.allUsers.subscribe((user) => {
      user.forEach((element: any) => {
        // console.log('element', element);
        let birthDate = element.birthDate
          ? element.birthDate.toDate()
          : 'N.V.T';
        let createdAt = element.createdAt ? element.createdAt : 'N.V.T';
        let privacyPolicyAcceptence = element.privacyPolicyAcceptDate
          ? element.privacyPolicyAcceptDate.toDate()
          : 'N.V.T';
        if (birthDate !== 'N.V.T') {
          const year = birthDate.getFullYear();
          const month = birthDate.getMonth();
          const day = birthDate.getDay();
          birthDate = year + '-' + month + '-' + day;
        }
        if (createdAt !== 'N.V.T') {
          const year = createdAt.getFullYear();
          const month = createdAt.getMonth();
          const day = createdAt.getDay();
          createdAt = year + '-' + month + '-' + day;
        }
        if (privacyPolicyAcceptence !== 'N.V.T') {
          const year = privacyPolicyAcceptence.getFullYear();
          const month = privacyPolicyAcceptence.getMonth();
          const day = privacyPolicyAcceptence.getDay();
          privacyPolicyAcceptence = year + '-' + month + '-' + day;
        }

        const objectToPush = {
          kaart_id: element.cardId ? element.cardId : 'N.V.T',
          naam: element.name ? element.name : 'N.V.T',
          email: element.email ? element.email : 'N.V.T',
          geboortedatum: birthDate,
          stad: element.city ? element.city : 'N.V.T',
          straatnaam: element.streetName ? element.streetName : 'N.V.T',
          Huisnummer: element.houseNumber ? element.houseNumber : 'N.V.T',
          postcode: element.postcode ? element.postcode : 'N.V.T',
          telefoonnummer: element.phoneNumber ? element.phoneNumber : 'N.V.T',
          credits: element.credits ? element.credits : 'N.V.T',
          account_type: element.accounttype ? element.accounttype : 'N.V.T',
          Gemaakt_op: createdAt,
          plan_naam: element.currentPlan ? element.currentPlan.name : 'N.V.T',
          plan_omschrijving: element.currentPlan
            ? element.currentPlan.description
            : 'N.V.T',
          plan_amount: element.currentPlan
            ? element.currentPlan.automaticAmount
            : 'N.V.T',
          plan_credits: element.currentPlan
            ? element.currentPlan.automaticCredits
            : 'N.V.T',
          plan_limiet: element.currentPlan
            ? element.currentPlan.automaticLimit
            : 'N.V.T',
          plan_start_bedrag: element.currentPlan
            ? element.currentPlan.startAmount
            : 'N.V.T',
          plan_start_credits: element.currentPlan
            ? element.currentPlan.startCredits
            : 'N.V.T',
          begeleiden_hond: element.guidedog ? element.guidedog : 'N.V.T',
          rollator: element.rollator ? element.rollator : 'N.V.T',
          privacy_policy_acceptatie_datum: privacyPolicyAcceptence,
        };
        this.allUsersExcel.push(objectToPush);
      });
    });
  }

  // export all users
  writeToExcel() {
    console.log('this.allUsersExcel', this.allUsersExcel);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.allUsersExcel); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Gebruikers');

    // /* save to file */
    XLSX.writeFile(wb, 'Gebruikers' + '.xlsx');
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    } else if (type === 'showHidden') {
      this.showHiddenChanged.next(this.showHidden);
    } else if (type === 'order') {
      this.orderByChanged.next(this.orderBy);
      this.orderDirectionChanged.next(this.orderDirection);
    }
  }

  copyEmail(email) {
    // this.clipboard.writeText(email);
    const el = document.createElement('textarea');
    el.value = email;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this._snackBar.open('E-mail gekopieerd.', 'X', {
      duration: 3000,
    });
  }

  checkFilters(user, filters) {
    this.itemsToShow = 20;
    // console.log('user', user);
    let passesHiddenFilter = true;
    let passesSearchFilter = true;

    if (
      (filters.showHidden && user.hidden) ||
      (!filters.showHidden && !user.hidden)
    ) {
      passesHiddenFilter = true;
    } else {
      passesHiddenFilter = false;
    }
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (user.name) {
        if (user.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
      if (user.email) {
        if (user.email.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter && passesHiddenFilter) {
      // console.log('user passes filter:', user);
      return user;
    }
  }

  async changeCredits(type, user) {
    console.log('user', user);
    const dialogRef = this.dialog.open(ChangeCreditsComponent, {
      width: '370px',
      data: { type, userId: user.id },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result: ', result);
      if (!result) {
        // for unexpecting closer
        return;
      }
      if (result.credits) {
        if (!result.pin) {
          this._snackBar.open('Geen pin ingevoerd', '', {
            duration: 5000,
          });
          return;
        }
        const pinDoc = await this.db
          .collection('settings/')
          .doc('general')
          .get();
        let pinAdd;
        let pinSubstract;
        await pinDoc.forEach((set) => {
          const settings = set.data() as Organisation;
          console.log('settings', settings);
          pinAdd = settings.pinAddCredits;
          pinSubstract = settings.pinSubstractCredits;
        });

        if (!pinAdd || !pinSubstract) {
          return this.showError(
            'Er is nog geen pin ingesteld bij instellingen'
          );
        }
        const pin = result.pin;
        if (type === 'add' && pinAdd !== pin) {
          return this.showError('Pin incorrect');
        }
        // console.log('retrieved pins', pinSubstract, pinAdd, 'result pin', pin);
        if (type === 'remove' && pinSubstract !== pin) {
          return this.showError('Pin incorrect');
        }
        let oldCredits;
        let newCredits;
        // await this.usersCollection.doc(user.id).update({ 'selectStatus': status });
        await this.db.firestore.runTransaction((transaction) =>
          transaction
            .get(this.usersCollection.doc(user.id).ref)
            .then((userDoc) => {
              if (userDoc.exists) {
                oldCredits = userDoc.data().credits || 0;
                if (type === 'add') {
                  newCredits = oldCredits + result.credits;
                }
                if (type === 'remove') {
                  newCredits = oldCredits - result.credits;
                }
                transaction.update(this.usersCollection.doc(user.id).ref, {
                  credits: newCredits,
                });
                // return newCredits;
              }
            })
        );
        let ref;
        let saveData;
        if (type === 'add') {
          ref = this.db.firestore.collection('payments/');
          saveData = {
            userRef: this.usersCollection.doc(user.id).ref,
            date: new Date(),
            creditsAdded: result.credits,
            // description: 'Handmatige bijschrijving',
            description: 'Contante betaling', // Is this needed for self adding credits to a person?
            status: 'paid',
            type: 'cash',
            remarks: result.remarks
              ? result.remarks
              : firestore.FieldValue.delete(),
            payedToWemaron: false,
            actionBy: this.plannerEmail,
          };
        }
        if (type === 'remove') {
          ref = this.db.firestore.collection('charges/');
          saveData = {
            userRef: this.usersCollection.doc(user.id).ref,
            date: new Date(),
            creditsSpent: result.credits,
            description: 'Handmatige afschrijving',
            remarks: result.remarks
              ? result.remarks
              : firestore.FieldValue.delete(),
            actionBy: this.plannerEmail,
          };
        }
        ref = ref.doc();
        await ref.set(saveData, { merge: true });
        if (type === 'add') {
          this._snackBar.open('Credits toegevoegd', '', {
            duration: 5000,
          });
        }
        if (type === 'remove') {
          this._snackBar.open('Credits verminderd', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async showCreditsHistory(user) {
    console.log('user', user);
    this.dialog.open(CreditsHistoryComponent, {
      data: { user },
    });
  }

  async changeHiddenStatus(status, user) {
    console.log('user', user);
    const dialogRef = this.dialog.open(ChangeHiddenStatusComponent, {
      width: '300px',
      data: { type: status, user },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result: ', result);
      if (result) {
        if (status === 'hide') {
          await this.usersCollection.doc(user.id).update({ hidden: true });
          this._snackBar.open('Deelnemer is verstopt', '', {
            duration: 5000,
          });
        } else if (status === 'show') {
          await this.usersCollection.doc(user.id).update({ hidden: false });
          this._snackBar.open('Deelnemer is weer zichtbaar', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async editUser(user) {
    console.log('user', user);
    this.dialog.open(UserComponent, {
      width: '500px',
      data: { user },
    });
  }

  async deleteUser(user) {
    console.log('user', user);
    const paymentsRef = this.db.collection<History>('payments', (ref) =>
      ref
        .where('userRef', '==', this.db.collection('users').doc(user.id).ref)
        .orderBy('date', 'desc')
        .limit(1)
    );
    const payments = await paymentsRef.get();
    let lastPayment = null;
    // tslint:disable-next-line: no-shadowed-variable
    await payments.forEach(async (payments) => {
      await payments.docs.forEach((payment) => {
        lastPayment = payment.data();
      });
    });
    const twoYearsAgoDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    );
    if (!lastPayment || lastPayment.date < twoYearsAgoDate) {
      // deleteAccount();
      const dialogRef = this.dialog.open(DeleteUserComponent, {
        width: '300px',
        data: { user },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log('The dialog was closed');
        if (result) {
          await this.db.collection('deletedUsersList').doc(user.id).set({
            email: user.email,
            deleteDate: new Date(),
          });
          this._snackBar.open('De deelnemer wordt binnenkort verwijdert.', '', {
            duration: 5000,
          });
        }
      });
    } else {
      this._snackBar.open(
        'Deze deelnemer kan niet verwijdert worden omdat zijn laatste betaling nog niet 2 jaar geleden is.',
        '',
        {
          duration: 5000,
        }
      );
    }
  }
  newUser() {
    const win = window.open(this.env.newUserLink, '_blank');
    win.focus();
  }

  showError(txt: string): void {
    this._snackBar.open(txt, 'X', {
      duration: 5000,
    });
  }
  // openFileInput() {
  //   const element: HTMLElement = document.getElementById('file') as HTMLElement;
  //   element.click();
  // }
  // uploadedFile(event) {
  //   this.fileUploaded = event.target.files[0];
  //   this.readExcel();
  // }
  // async readExcel() {
  //   const readFile = new FileReader();
  //   readFile.onload = (e) => {
  //     this.storeData = readFile.result;
  //     const data = new Uint8Array(this.storeData);
  //     const arr = new Array();
  //     for (let i = 0; i !== data.length; ++i) {
  //       arr[i] = String.fromCharCode(data[i]);
  //     }
  //     const bstr = arr.join('');
  //     const workbook = XLSX.read(bstr, { type: 'binary' });
  //     const firstSheetName = workbook.SheetNames[0];
  //     this.worksheet = workbook.Sheets[firstSheetName];
  //     const spreadsheet = {};
  //     const batches = [];
  //     Object.keys(this.worksheet).forEach((key) => {
  //       if (key !== '!ref' && key !== '!margins' && key !== '!autofilter') {
  //         // console.log('key', key);
  //         const rowId = key.match(/\d+/g).toString();
  //         const colId = key.match(/[a-zA-Z]+/g).toString();
  //         if (!spreadsheet[rowId]) {
  //           spreadsheet[rowId] = {};
  //         }
  //         spreadsheet[rowId][colId] = this.worksheet[key].w;
  //       }
  //     });
  //     const columnNames = spreadsheet[1];
  //     delete spreadsheet[1];

  //     batches[0] = this.db.firestore.batch();
  //     let batchIndex = 0;

  //     Object.keys(spreadsheet).forEach((key) => {
  //       const user = spreadsheet[key];
  //       console.log('user', user);
  //       if (batches[batchIndex]['_mutations'].length === 500) {
  //         batchIndex++;
  //         batches[batchIndex] = this.db.firestore.batch();
  //       }
  //       console.log('user.id', user.A, 'user.email:', user.C);
  //       batches[batchIndex].set(
  //         this.db.collection('deletedUsersList').doc(user.A).ref,
  //         {
  //           deleteDate: new Date(),
  //           email: user.C,
  //         },
  //         { merge: true }
  //       );
  //     });

  //     console.log('batches', batches);
  //     batches.forEach(async (batch) => {
  //       // console.log(batch);
  //       await batch.commit();
  //       console.log('batch commited');
  //     });
  //   };
  //   readFile.readAsArrayBuffer(this.fileUploaded);
  //   console.log('this.file', this.fileUploaded);
  // }
}
