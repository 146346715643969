<h1 mat-dialog-title *ngIf="newRide">Nieuwe rit</h1>
<h1 mat-dialog-title *ngIf="!newRide">Rit bewerken
    <button mat-icon-button (click)="deleteRide()" matTooltip="Rit verwijderen">
        <mat-icon>delete</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <form fxLayout="column" (ngSubmit)="save()" [formGroup]="rideForm">
        <mat-form-field>
            <mat-label>Voertuig voor deze rit</mat-label>
            <mat-select formControlName="vehicleId" required (selectionChange)="vehicleChanged()">
                <mat-option *ngFor="let vehicle of vehicles | async" [value]="vehicle.id">{{vehicle.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="users" *ngIf="icons | async">
            <div>Personen in rit</div>
            <button type="button" mat-icon-button matTooltip="Nieuwe deelnemer in rit toevoegen."
                (click)="changeUser()">
                <mat-icon>person_add</mat-icon>
            </button>
            <div *ngIf="users.length === 0" class="no-users-yet">Er zijn nog geen deelnemers in de rit.</div>
            <div *ngIf="users.length != 0" cdkDropList class="users-list" (cdkDropListDropped)="drop($event)">
                <div class="user-box" *ngFor="let user of users; let i=index" cdkDrag fxLayout="row">
                    <div class="main" fxLayout="column">
                        <div class="title">{{user.title}}</div>
                        <div *ngIf="user.remarks" class="remarks">{{user.remarks}}</div>
                        <div class="icons" fxLayout="row">
                            <div class="icon" [ngClass]="{'active': user[icon.id] === true}" [matTooltip]="icon.name"
                                *ngFor="let icon of icons | async">
                                <mat-icon *ngIf="icon.iconType == 'material'">
                                    {{icon.icon}}</mat-icon>
                                <div class="mat-icon" *ngIf="icon.iconType == 'custom'"
                                    [inlineSVG]="'/assets/icons/' + icon.icon + '.svg'"></div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <button type="button" [disabled]="i == 0" mat-icon-button (click)="moveUser('up', i, user)">
                            <mat-icon>arrow_drop_up</mat-icon>
                        </button>
                        <button type="button" [disabled]="i == users.length - 1" mat-icon-button
                            (click)="moveUser('down', i, user)">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <button type="button" mat-icon-button (click)="changeUser(i, user)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button type="button" mat-icon-button (click)="removeUser(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="seats" *ngIf="selectedVehicle && availableSeats !== undefined">Vrije plaatsen in de rit
                <span>{{availableSeats}}/{{selectedVehicle['seats']}}</span>
            </div>
        </div>
        <!-- <form fxLayout="column" (ngSubmit)="save()" [formGroup]="rideForm"> -->
        <mat-form-field>
            <mat-label>Opmerkingen</mat-label>
            <textarea formControlName="comments" matInput></textarea>
        </mat-form-field>
        <div class="datetime" fxLayout="row">
            <mat-form-field class="date">
                <mat-label>Begindatum</mat-label>
                <input matInput [matDatepicker]="start" formControlName="start" required
                    (dateChange)="dateUpdated('start')">
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="hour">
                <mat-label>Uur</mat-label>
                <mat-select formControlName="startHour" required (selectionChange)="dateUpdated('start')">
                    <mat-option *ngFor=" let hour of hours" [value]="hour">{{hour}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="minute">
                <mat-label>Minuut</mat-label>
                <mat-select formControlName="startMinute" required (selectionChange)="dateUpdated('start')">
                    <mat-option *ngFor="let minute of minutes" [value]="minute.val">{{minute.text}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="datetime" fxLayout="row">
            <mat-form-field class="date">
                <mat-label>Einddatum</mat-label>
                <input matInput [matDatepicker]="end" formControlName="end" required (dateChange)="dateUpdated('end')">
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="hour">
                <mat-label>Uur</mat-label>
                <mat-select formControlName="endHour" required (selectionChange)="dateUpdated('end')">
                    <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="minute">
                <mat-label>Minuut</mat-label>
                <mat-select formControlName="endMinute" required (selectionChange)="dateUpdated('end')">
                    <mat-option *ngFor="let minute of minutes" [value]="minute.val">{{minute.text}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-form-field>
            <mat-label>Standaard ritprijs</mat-label>
            <mat-select formControlName="rideProduct" required>
                <mat-option *ngFor="let product of products | async" [value]="product.id">{{product.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Annuleren</button>
    <button mat-raised-button [disabled]="!rideForm.valid || this.availableSeats < 0" (click)="save()"
        color="primary">Opslaan</button>
</div>