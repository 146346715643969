<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
    <div class="header" fxLayout="row">
        <div class="h-title" fxFlex>
            <mat-icon>people</mat-icon>
            <span>Gebruikersrechten</span>
        </div>
        <div class="search">
            <mat-form-field color="accent">
                <mat-label class="placeholder">Zoeken</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="searchQuery" (ngModelChange)="onFilterChange('search', $event)">
            </mat-form-field>
        </div>
    </div>
    <ng-template #loading>
        <div id="loading" fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
    <mat-card *ngIf="filteredUsers | async as users else loading">
        <div class="title" fxLayout="row">
            <h3 fxFlex>Gebruikersrechten <span>({{totalUsers}})</span></h3>
        </div>
        <mat-table #table [dataSource]="users">
            <!-- Checkbox Column -->
            <!-- <ng-container matColumnDef="checkbox">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-checkbox [(ngModel)]="checkboxes[user.id]" (ngModelChange)="onSelectedChange(user.id)"
                    (click)="$event.stopPropagation()">
                </mat-checkbox>
            </mat-cell>
        </ng-container> -->

            <!-- Avatar Column -->
            <!-- <ng-container matColumnDef="avatar">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let user">
                <img class="avatar" *ngIf="user.avatar" [alt]="user.name" [src]="user.avatar" />
            </mat-cell>
        </ng-container> -->

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="user.paymentFailed" matTooltip="Er is een fout opgetreden bij het betalen.">
                        <mat-icon class="warn-icon">error</mat-icon>
                    </span>
                    <p class="text-truncate">
                        {{user.name}} {{user.lastName}}
                        <span *ngIf="user.credits">({{user.credits}})</span>
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>E-mail</mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <p class="email text-truncate">
                        {{user.email}}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Account Type Column -->
            <ng-container matColumnDef="accountType">
                <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Type</mat-header-cell>
                <mat-cell *matCellDef="let user" fxHide fxShow.gt-md>
                    <p class="accountType text-truncate" *ngIf="user.accountType == 'driver'">
                        Chauffeur
                    </p>
                    <p class="accountType text-truncate" *ngIf="user.accountType == 'planner'">
                        Planner
                    </p>
                    <p class="accountType text-truncate" *ngIf="user.accountType == 'admin'">
                        Administrator
                    </p>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let user; columns: displayedColumns;" class="user" (click)="editUser(user)" matRipple>
            </mat-row>
        </mat-table>
    </mat-card>
</div>
<button mat-fab class="fab-fixed" (click)="editUser()">
    <mat-icon>add</mat-icon>
</button>