import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateAdapter } from '@angular/material/core';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { ColorPickerModule } from 'ngx-color-picker';
// import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from 'angular-material-components/projects/color-picker';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { InlineSVGModule } from 'ng-inline-svg';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { CustomDateAdapter } from './custom-date-adapter';

import { AvatarModule } from 'ngx-avatar';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  CalendarModule,
  DateAdapter as AngularCalendarDateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AgmCoreModule } from '@agm/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import {
//   MatMomentDateModule,
//   MomentDateAdapter,
// } from '@angular/material-moment-adapter';

import { ConstantsService } from './common/services/constants.service';
import { PlannerComponent } from './planner/planner.component';
import { UsersComponent } from './users/users.component';
import { ChangeCreditsComponent } from './users/dialogs/change-credits/change-credits.component';
import { UserComponent } from './users/dialogs/user/user.component';
import { CreditsHistoryComponent } from './users/dialogs/credits-history/credits-history.component';
import { ChangeHiddenStatusComponent } from './users/dialogs/change-hidden-status/change-hidden-status.component';
import { DeleteUserComponent } from './users/dialogs/delete-user/delete-user.component';
import { RideDetailsComponent } from './planner/dialogs/ride-details/ride-details.component';
import { EditRideComponent } from './planner/dialogs/edit-ride/edit-ride.component';
import { CopyRideComponent } from './planner/dialogs/copy-ride/copy-ride.component';
import { UserRideComponent } from './planner/dialogs/edit-ride/dialogs/user-ride/user-ride.component';
import { FrequentAddressComponent } from '../app/settings/frequent-address/frequent-address.component';
import { EditAddressComponent } from '../app/settings/frequent-address/dialogs/edit-address/edit-address.component';
import { AuditComponent } from '../app/settings/audit/audit.component';
import { DeleteRideComponent } from './planner/dialogs/edit-ride/dialogs/delete-ride/delete-ride.component';
import { SettingsComponent } from './settings/settings.component';
import { VehiclesComponent } from './settings/vehicles/vehicles.component';
import { ImportExportComponent } from './settings/dialog/import-export/import-export.component';
import { CreateEditVehiclesComponent } from './settings/vehicles/dialogs/create-edit-vehicles/create-edit-vehicles.component';
import { WarningComponent } from './settings/dialog/warning/warning.component';
import { TariffsComponent } from './settings/tariffs/tariffs.component';
import { CreateEditTariffComponent } from './settings/tariffs/dialogs/create-edit-tariff/create-edit-tariff.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminUserComponent } from './admin-users/dialogs/user/user.component';
import { CopyUpdateComponent } from './planner/dialogs/copy-update/copy-update.component';
import { ConfirmChangeComponent } from './planner/dialogs/confirm-change/confirm-change.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    RegistrationComponent,
    PlannerComponent,
    UsersComponent,
    UserComponent,
    AdminUsersComponent,
    AdminUserComponent,
    ChangeCreditsComponent,
    CreditsHistoryComponent,
    ChangeHiddenStatusComponent,
    DeleteUserComponent,
    RideDetailsComponent,
    EditRideComponent,
    CopyRideComponent,
    UserRideComponent,
    FrequentAddressComponent,
    EditAddressComponent,
    AuditComponent,
    DeleteRideComponent,
    SettingsComponent,
    VehiclesComponent,
    ImportExportComponent,
    CreateEditVehiclesComponent,
    WarningComponent,
    TariffsComponent,
    CreateEditTariffComponent,
    CopyUpdateComponent,
    ConfirmChangeComponent,
  ],
  imports: [
    AppRoutingModule,
    AvatarModule,
    NgxCsvParserModule,
    CalendarModule.forRoot({
      provide: AngularCalendarDateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCojBc1zNDl7y-xJA4J_ML6t2azl_C7eOU', // AIzaSyBa8IaoNVTApq_VIRtKq45Kl5RMhf_fcIc
      libraries: ['places'],
    }),
    InlineSVGModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // MatMomentDateModule,
    // MomentDateAdapter,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    MatRippleModule,
    MatCardModule,
    MatCheckboxModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatCardModule,
    MatExpansionModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    ColorPickerModule,
  ],
  entryComponents: [
    LoginComponent,
    RegistrationComponent,
    PlannerComponent,
    UsersComponent,
    UserComponent,
    AdminUsersComponent,
    AdminUserComponent,
    ChangeCreditsComponent,
    CreditsHistoryComponent,
    ChangeHiddenStatusComponent,
    DeleteUserComponent,
    RideDetailsComponent,
    EditRideComponent,
    ConfirmChangeComponent,
    CopyRideComponent,
    UserRideComponent,
    FrequentAddressComponent,
    EditAddressComponent,
    AuditComponent,
    DeleteRideComponent,
    SettingsComponent,
    VehiclesComponent,
    ImportExportComponent,
    CreateEditVehiclesComponent,
    WarningComponent,
    TariffsComponent,
    CreateEditTariffComponent,
    CopyUpdateComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    ConstantsService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
