<!-- Background video should be dynamic, must come from DB -->
<div class="login-wrapper" fxLayout="row">
    <!-- <div fxLayout="row"> -->
    <div class="login-intro" fxFlex="75" fxHide.xs>
        <!-- <video autoplay muted loop id="background-video" fxFlex.xs fxHide fxShow.gt-xs>
                <source src="{{ video }}" type="video/mp4">
            </video> -->
        <!-- <div class="logo-image">
                <img class="logo" src="../../../assets/images/logos/Bob_de_Bot.png"/>
                <div class="title">
                    Welkom bij Bob de Bot!
                </div>
            </div> -->
    </div>
    <div class="login-form-wrapper" fxFlex>
        <mat-card class="login-form" fxLayout="column" fxLayoutAlign="center center">
            <div class="login-logo">
                <img class="logo">
            </div>

            <div class="title">Log nu in voor {{ name }}!</div>

            <div class="email-login" fxLayout="column" fxLayoutAlign="center center">
                <mat-form-field class="full-width" *ngIf="!checkSendSMS">
                    <input type="tel" matInput placeholder="Telefoonnummer" [(ngModel)]="phone" required>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="!checkSendSMS">
                    <input matInput type="text" placeholder="Voornaam" [(ngModel)]="firstName" required>
                </mat-form-field>

                <div id="recaptcha-container"></div>

                <div *ngIf="windowRef.confirmationResult" fxLayout="column" fxLayoutAlign="center center">
                    <mat-form-field class="full-width">
                        <input matInput name="code" [(ngModel)]="verificationCode"
                            placeholder="Vul je verificatie code in">
                    </mat-form-field>

                    <button mat-raised-button color="primary" class="login-button"
                        (click)="verifySignUpCode()">Verify</button>
                </div>

                <mat-checkbox *ngIf="!checkSendSMS" [(ngModel)]="acceptPrivacy">Ik ga akkoord met de algemene<br>
                    voorwaarden van {{ name }}.</mat-checkbox>

                <div *ngIf="!checkSendSMS">
                    <button *ngIf="firstName && acceptPrivacy && phone" mat-raised-button color="primary"
                        class="login-button" (click)="sendSignUpCode()">Registreer</button>
                    <button *ngIf="!firstName || !acceptPrivacy || !phone" mat-raised-button color="primary"
                        class="login-button" disabled>Registreer</button>
                </div>
            </div>

        </mat-card>
    </div>
    <!-- </div> -->
</div>