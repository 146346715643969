import { NgModule } from '@angular/core';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { Routes, Router, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  customClaims,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { PlannerComponent } from './planner/planner.component';
import { UsersComponent } from './users/users.component';
import { AuditComponent } from '../app/settings/audit/audit.component';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { FrequentAddressComponent } from '../app/settings/frequent-address/frequent-address.component';
import { SettingsComponent } from './settings/settings.component';
import { VehiclesComponent } from './settings/vehicles/vehicles.component';
import { TariffsComponent } from './settings/tariffs/tariffs.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedIn = () => redirectLoggedInTo(['planner']);
const adminOnly = () =>
  pipe(
    customClaims,
    map((claims) => {
      console.log('claims', claims);
      if (claims.accountType === 'admin') {
        return true;
      } else {
        return [redirectUnauthorizedToLogin];
      }
    })
  );
const plannerAndAdmin = () =>
  pipe(
    customClaims,
    map((claims) => {
      console.log('claims', claims);
      if (claims.accountType === 'admin' || claims.accountType === 'planner') {
        return true;
      } else {
        return [redirectUnauthorizedToLogin];
      }
    })
  );
// tslint:disable-next-line: prefer-const
let routes: Routes = [];
const allRoutes = {
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
  },
  registration: {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
  },
  planner: {
    path: 'planner',
    component: PlannerComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  users: {
    path: 'users',
    component: UsersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: plannerAndAdmin },
  },
  adminUsers: {
    path: 'admin-users',
    component: AdminUsersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
  frequentAddress: {
    path: 'frequentAddress',
    component: FrequentAddressComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
  audit: {
    path: 'audit',
    component: AuditComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: plannerAndAdmin },
  },
  settings: {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
  settingsVehicles: {
    path: 'settingsVehicles',
    component: VehiclesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
  settingsTariffs: {
    path: 'settingsTariffs',
    component: TariffsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
};
console.log('allroutes', allRoutes);
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach((route) => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: 'planner',
      // canActivate: [AngularFireAuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
    console.log('router3', router);
  }
}
