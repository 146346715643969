import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
  getWeekYear,
  getWeek,
} from 'date-fns';
import { Observable, Subject } from 'rxjs';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { RideDetailsComponent } from './dialogs/ride-details/ride-details.component';
import { ConfirmChangeComponent } from './dialogs/confirm-change/confirm-change.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Product,
  EditRideComponent,
} from './dialogs/edit-ride/edit-ride.component';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { CopyUpdateComponent } from './dialogs/copy-update/copy-update.component';
import { environment } from 'src/environments/environment';

const colors: any = {
  blue: {
    primary: '#1e90ff',
    secondary: '#1e90ff',
  },
};

export interface Ride {
  id: string;
  title: string;
  availableSeats: number;
  end: Date;
  start: Date;
  vehicleId: string;
  vehicleRef: DocumentReference;
  comments: string;
  rideProduct: Product;
  users: Map<string, any>;
  vehicle: Vehicle;
  parentRideId: string;
  year: number;
  month: number;
  day: number;
  week: string;
}
// export interface RideAsGotten {
//   id: string;
//   availableSeats: number;
//   end: firestore.Timestamp;
//   start: firestore.Timestamp;
//   vehicleId: string;
//   vehicleRef: string;
//   comments: string;
//   rideProduct: Product;
//   users: Map<string, {}>;
// }
export interface RideId extends Ride {
  id: string;
}

export interface Vehicle {
  id: string;
  name: string;
  seats: number;
  calendarStyle: {
    color: string;
  };
}
export interface VehicleId extends Vehicle {
  id: string;
}

// function getTimezoneOffsetString(date: Date): string {
//   const timezoneOffset = date.getTimezoneOffset();
//   const hoursOffset = String(
//     Math.floor(Math.abs(timezoneOffset / 60))
//   ).padStart(2, '0');
//   const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
//   const direction = timezoneOffset > 0 ? '-' : '+';

//   return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
// }
@Component({
  selector: 'app-planner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class PlannerComponent implements OnInit {
  env = environment;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Week;
  weekdays = [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterdag',
  ];

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  viewBeginDate: Date = new Date();
  viewEndDate: Date = new Date();

  rides: Observable<RideId[]>;
  vehicles: Observable<VehicleId[]>;
  filterOnVehicle: string;
  events$: Observable<Array<CalendarEvent<{ ride: RideId }>>>;

  ridesCollection: AngularFirestoreCollection<Ride>;
  vehicleCollection: AngularFirestoreCollection<Vehicle>;

  vehiclesArray: Array<Vehicle> = [];

  activeDayIsOpen = false;

  constructor(
    public db: AngularFirestore,
    private http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.ridesCollection = this.db.collection<Ride>('rides/');
  }

  ngOnInit(): void {
    this.filterOnVehicle = 'all';
    this.vehicleCollection = this.db.collection<Vehicle>('vehicles', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.vehicles.subscribe((vehicles) => {
      this.vehiclesArray = vehicles;
      this.fetchEvents();
    });
    this.fetchEvents();
  }

  fetchEvents(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    // const params = new HttpParams()
    //   .set(
    //     'primary_release_date.gte',
    //     format(getStart(this.viewDate), 'dd-MM-yyyy')
    //   )
    //   .set(
    //     'primary_release_date.lte',
    //     format(getEnd(this.viewDate), 'dd-MM-yyyy')
    //   )
    //   .set('api_key', '0ec33936a68018857d727958dca1424f');
    this.viewBeginDate = getStart(this.viewDate, { weekStartsOn: 1 });
    this.viewEndDate = getEnd(this.viewDate, { weekStartsOn: 1 });
    console.log('getStart(this.viewDate)', getStart(this.viewDate));
    console.log('this.view', this.view);
    let ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
      ref.where('start', '>', getStart(this.viewDate))
    );

    const selectedYear = this.viewBeginDate.getFullYear();
    if (this.view === 'month') {
      const selectedMonth = this.viewBeginDate.getMonth();
      ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
        ref
          .where('year', '==', selectedYear)
          .where('month', '==', selectedMonth)
      );
    }
    if (this.view === 'week') {
      const selectedWeek = getWeekString(this.viewEndDate);
      console.log('selectedWeek', selectedWeek);
      ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
        ref
          // .where('year', '==', selectedYear)
          .where('week', '==', selectedWeek)
      );
    }
    if (this.view === 'day') {
      const selectedMonth = this.viewBeginDate.getMonth();
      const selectedDay = this.viewBeginDate.getDate();
      ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
        ref
          .where('year', '==', selectedYear)
          .where('month', '==', selectedMonth)
          .where('day', '==', selectedDay)
      );
    }
    this.rides = ridesCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as object;
          if (data['start']) {
            data['start'] = data['start'].toDate();
          }
          if (data['end']) {
            data['end'] = data['end'].toDate();
          }
          const rideData = data as Ride;
          const id = a.payload.doc.id;
          return { id, ...rideData };
        })
      )
    );
    this.events$ = this.rides.pipe(
      map((rides) => {
        console.log('rides', rides);
        return rides
          .map((ride) => {
            let color = colors.blue;
            const selectedVehicle = this.vehiclesArray.find((vehicle) => {
              try {
                return vehicle.id === ride.vehicleRef.id;
              } catch {
                console.log(ride.id, ride.vehicleRef);
              }
            });
            if (selectedVehicle) {
              color = {
                primary: selectedVehicle.calendarStyle.color,
                secondary: selectedVehicle.calendarStyle.color,
                // secondary: tinycolor(selectedVehicle.calendarStyle.color).brighten(25),
              };
            }
            const title = ride.title
              ? ride.title
              : ride.availableSeats === 0
              ? 'VOL'
              : `${ride.availableSeats} vrij`;
            return {
              title,
              start: ride.start,
              end: ride.end,
              color,
              meta: {
                ride,
                vehicle: selectedVehicle,
              },
              draggable: true,
            };
          })
          .filter((ride) => {
            if (ride.meta.vehicle) {
              if (
                this.filterOnVehicle === 'all' ||
                ride.meta.vehicle.id === this.filterOnVehicle
              ) {
                // Return ride if it passes filter
                return ride;
              }
            }
          });
      })
    );

    this.events$.subscribe((ev) => {
      console.log('events$', ev);
    });
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: Array<CalendarEvent<{ ride: Ride }>>;
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventClicked(event: CalendarEvent<{ ride: Ride; vehicle: Vehicle }>): void {
    // console.log('ride', event.meta.ride);
    this.dialog.open(RideDetailsComponent, {
      width: '500px',
      data: { ride: event.meta.ride, vehicle: event.meta.vehicle },
    });
  }
  eventTimesChanged({
    type,
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    const oldStart = event.start;
    const oldEnd = event.end;
    let year = newStart.getFullYear();
    let month = newStart.getMonth();
    let week = getWeekString(newStart);
    let day = newStart.getDate();
    console.log('type', type);
    console.log('event', event);

    let actuallyMoved = false;
    if (newStart.toUTCString() !== oldStart.toUTCString()) {
      console.log('start changed');
      actuallyMoved = true;
    }
    if (newEnd.toUTCString() !== oldEnd.toUTCString()) {
      console.log('end changed');
      actuallyMoved = true;
    }
    if (actuallyMoved) {
      const confirmDialogRef = this.dialog.open(ConfirmChangeComponent, {
        width: '600px',
      });
      confirmDialogRef.afterClosed().subscribe(async (confirmResult) => {
        console.log('The dialog was closed with result: ', confirmResult);
        if (confirmResult) {
          const saveObj = {
            start: newStart,
            end: newEnd,
            year,
            month,
            week,
            day,
          };

          ////////////////////
          if (event.meta.ride.parentRideId) {
            // all updated rides should get this new parentRideId so they are unlinked from past events
            saveObj['parentRideId'] = event.meta.ride.id;
            // ride is part of group, ask if users wants to edit all
            // if yes, override all data in future rides (after recalculating the date)
            const updateDialogRef = this.dialog.open(CopyUpdateComponent, {
              width: '600px',
              data: {
                oldRide: event.meta.ride,
                newRide: saveObj,
              },
            });
            updateDialogRef.afterClosed().subscribe(async (result) => {
              console.log('The dialog was closed with result: ', result);
              if (result) {
                // sucessfully updated copies (or an error!)
                await this.db
                  .collection('rides')
                  .doc(event.meta.ride.id)
                  .set(saveObj, { merge: true });
                const snackBarRef = this._snackBar.open(
                  'Ritten zijn verplaatst',
                  '',
                  { duration: 5000 }
                );
                updateDialogRef.close();
              } else if (result === false) {
                // result false / user said 'no'
                // if no, remove parentRideId from this ride, then continue saving.
                saveObj['parentRideId'] = firestore.FieldValue.delete();
                await this.db
                  .collection('rides')
                  .doc(event.meta.ride.id)
                  .set(saveObj, { merge: true });
                const snackBarRef = this._snackBar.open(
                  'Rit is verplaatst',
                  '',
                  { duration: 5000 }
                );
                updateDialogRef.close();
              }
              // result undefined / user cancelled dialog, do nothing, user can press save again if they want to.
            });
          } else {
            await this.db
              .collection('rides')
              .doc(event.meta.ride.id)
              .set(saveObj, { merge: true });
            const snackBarRef = this._snackBar.open('Rit is verplaatst', '', {
              duration: 5000,
            });
          }
          ////////////////
          // NOTE::supporting the 'reverse change' stuff while also updating all copies gets a bit complex for now.
          // so removing it since we added the confirm dialog already
          // ::
          // snackBarRef.onAction().subscribe(() => {
          //   year = oldStart.getFullYear();
          //   month = oldStart.getMonth();
          //   week = getWeekString(oldStart);
          //   day = oldStart.getDate();
          //   console.log('event', event);
          //   rideRef.update({ start: oldStart, end: oldEnd, year, month, week, day });
          // });
        }
      });
    }
  }
  setView(view: CalendarView) {
    this.view = view;
    this.fetchEvents();
  }
  vehicleChanged() {
    console.log('this.filterOnVehicle', this.filterOnVehicle);
    this.fetchEvents();
  }
  newRide(date?) {
    let start;
    let end;
    if (date) {
      start = date;
    } else {
      start = new Date();
      start.setHours(7, 0, 0, 0);
    }
    if (date) {
      end = new Date(start.getTime() + 15 * 60000);
    } else {
      end = new Date();
      end.setHours(7, 15, 0, 0);
    }
    this.dialog.open(EditRideComponent, {
      width: '500px',
      data: {
        ride: {},
        defaultVehicle: this.filterOnVehicle,
        dates: { start, end },
      },
    });
  }
}

function getWeekString(date) {
  return (
    getWeekYear(date, {
      weekStartsOn: 1,
    }) +
    '-' +
    getWeek(date, {
      weekStartsOn: 1,
    })
  );
}
