import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { Ride } from '../../planner.component';
import { Product } from '../edit-ride/edit-ride.component';

import { AngularFirestore } from '@angular/fire/firestore';
import { getWeekYear, getWeek } from 'date-fns';
export interface DialogData {
  oldRide: Ride;
  newRide: Ride;
  delete: boolean;
}
@Component({
  selector: 'app-copy-update',
  templateUrl: './copy-update.component.html',
  styleUrls: ['./copy-update.component.scss'],
})
export class CopyUpdateComponent implements OnInit {
  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CopyUpdateComponent>
  ) {}

  ngOnInit() {
    console.log('data', this.data);
  }
  async updateCopies() {
    const oldRide = this.data.oldRide;
    const newRide = this.data.newRide;
    const startOffset = this.calculateDateDifference(
      oldRide.start,
      newRide.start
    );
    const endOffset = this.calculateDateDifference(oldRide.end, newRide.end);
    console.log('startOffset', startOffset);
    console.log('endOffset', endOffset);
    const products = (await this.db.firestore.collection('products').get())
      .docs;
    console.log('products', products);
    const ridesToUpdate = await this.db
      .collection('rides')
      .ref.where('parentRideId', '==', oldRide.parentRideId)
      .where('start', '>', oldRide.start)
      .get()
      .then((collection) => {
        const rides = [];
        collection.forEach((doc) => {
          const ride = doc.data() as any;
          ride.id = doc.id;
          ride.start = ride.start.toDate(); // might be a good way to make sure all timestamp are always usable as dates in the app
          ride.end = ride.end.toDate(); // can always remove this if this seems like a bad idea later :)
          rides.push(ride);
        });
        return rides;
      });
    console.log('ridesToUpdate', ridesToUpdate);
    const batch = this.db.firestore.batch();

    ridesToUpdate.forEach((ride: Ride) => {
      // !!! new parentRideId when updating, to ensure that only the newly updated rides are in the same 'chain'
      let updatedRide: Ride;
      const oldStartDate = ride.start;
      const newStartDate = new Date(oldStartDate);
      newStartDate.setMonth(newStartDate.getMonth() + startOffset.months);
      newStartDate.setDate(newStartDate.getDate() + startOffset.days);
      newStartDate.setHours(newStartDate.getHours() + startOffset.hours);
      newStartDate.setMinutes(newStartDate.getMinutes() + startOffset.minutes);

      const oldEndDate = ride.end;
      const newEndDate = new Date(oldEndDate);
      newEndDate.setMonth(newEndDate.getMonth() + endOffset.months);
      newEndDate.setDate(newEndDate.getDate() + endOffset.days);
      newEndDate.setHours(newEndDate.getHours() + endOffset.hours);
      newEndDate.setMinutes(newEndDate.getMinutes() + endOffset.minutes);

      // done setting new dates, now override all ride data with new ride, then override dates again.
      console.log('newRide', newRide);
      updatedRide = { ...newRide };

      updatedRide.start = newStartDate;
      updatedRide.end = newEndDate;
      updatedRide.id = ride.id;
      updatedRide.year = newStartDate.getFullYear();
      updatedRide.month = newStartDate.getMonth();
      updatedRide.week = getWeekString(newStartDate);
      updatedRide.day = newStartDate.getDate();
      console.log('updatedRide', updatedRide);
      //
      // Make sure copied rides have the correct and updated products in them, not the old values :)
      if (ride.rideProduct) {
        const selectedProduct = products.find((product) => {
          return product.id === ride.rideProduct.id;
        });
        if (selectedProduct) {
          ride.rideProduct = selectedProduct.data() as Product;
        }
      }
      if (updatedRide.users) {
        for (const [userId, user] of Object.entries(updatedRide.users)) {
          if (user.rideProduct) {
            const selectedProduct = products.find((product) => {
              return product.id === user.rideProduct.id;
            });
            if (selectedProduct) {
              user.rideProduct = selectedProduct.data() as Product;
            }
          }
          delete user.paid;
          delete user.charge;
        }
      }

      batch.set(this.db.collection('rides').doc(ride.id).ref, updatedRide, {
        merge: true,
      });
    });
    await batch.commit();
    this.dialogRef.close('done');
  }

  async deleteCopies() {
    const oldRide = this.data.oldRide;
    const ridesToDelete = await this.db
      .collection('rides')
      .ref.where('parentRideId', '==', oldRide.parentRideId)
      .where('start', '>', oldRide.start)
      .get()
      .then((collection) => {
        const rides = [];
        collection.forEach((doc) => {
          const ride = doc.data() as any;
          ride.ref = doc.ref;
          rides.push(ride);
        });
        return rides;
      });
    console.log('ridesToDelete', ridesToDelete);
    const batch = this.db.firestore.batch();

    ridesToDelete.forEach((ride) => {
      batch.delete(ride.ref);
    });
    await batch.commit();
    this.dialogRef.close('done');
  }

  calculateDateDifference(oldDate: Date, newDate: Date) {
    const monthDiff = newDate.getMonth() - oldDate.getMonth();
    const dayDiff = newDate.getDate() - oldDate.getDate();
    const hourDiff = newDate.getHours() - oldDate.getHours();
    const minuteDiff = newDate.getMinutes() - oldDate.getMinutes();
    console.log('monthDiff', monthDiff);
    console.log('dayDiff', dayDiff);
    console.log('hourDiff', hourDiff);
    console.log('minuteDiff', minuteDiff);
    return {
      months: monthDiff,
      days: dayDiff,
      hours: hourDiff,
      minutes: minuteDiff,
    };
  }
}
function getWeekString(date) {
  return (
    getWeekYear(date, {
      weekStartsOn: 1,
    }) +
    '-' +
    getWeek(date, {
      weekStartsOn: 1,
    })
  );
}
