<h1 mat-dialog-title>
  {{ data.user.name }} <span>({{ data.user.credits }})</span>
</h1>
<div mat-dialog-content>
  Credits historie
  <mat-list *ngIf="history">
    <mat-list-item *ngIf="history.length === 0">
      Deze Deelnemer heeft nog geen credits gehad.
    </mat-list-item>
    <mat-list-item *ngFor="let history of history">
      <h3 matLine>{{ history.description }}</h3>
      <p matLine *ngIf="history.creditsSpent">
        <span class="red">-{{ history.creditsSpent }}</span>
        {{ history.date.toDate() | date: 'd/MM/yyyy HH:mm' }}
      </p>
      <p matLine *ngIf="history.creditsAdded">
        <span class="green">+{{ history.creditsAdded }}</span>
        {{ history.date.toDate() | date: 'd/MM/yyyy HH:mm' }}
      </p>
      <p matLine *ngIf="history.actionBy && history.creditsAdded">
        <span>Toegevoegd door {{ history.actionBy }}</span>
      </p>
      <p matLine *ngIf="history.actionBy && history.creditsSpent">
        <span>Verminderd door {{ history.actionBy }}</span>
      </p>
    </mat-list-item>
  </mat-list>
  <!-- <mat-form-field>
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field> -->
</div>
<!-- <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Nee</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
</div> -->
