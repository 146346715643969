<app-nav-bar></app-nav-bar>
<div class="users" fxLayout="column">
  <div class="header" fxLayout="row">
    <div class="h-title" fxFlex>
      <mat-icon>people</mat-icon>
      <span>Deelnemers</span>
    </div>
    <button mat-raised-button (click)="writeToExcel()">
      <mat-icon style="margin-right: 4px">publish</mat-icon>
      Exporteren
    </button>
    <div class="search">
      <mat-form-field color="accent">
        <mat-label class="placeholder">Zoeken</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [(ngModel)]="searchQuery" (ngModelChange)="onFilterChange('search', $event)" />
      </mat-form-field>
    </div>
  </div>
  <ng-template #loading>
    <div id="loading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <mat-card *ngIf="filteredUsers | async as users; else loading">
    <div class="title" fxLayout="row">
      <h3 fxFlex>
        Alle Deelnemers <span>({{ totalUsers }})</span>
      </h3>
      <mat-action-list>
        <!-- <button
          class="import-button"
          mat-raised-button
          type="button"
          (click)="openFileInput()"
        >
          Gebruikers verwijderen
        </button>
        <input
          style="visibility: hidden; height: 0"
          type="file"
          id="file"
          class="form-control"
          (change)="uploadedFile($event)"
          placeholder="Upload file"
          accept=".xlsx"
        /> -->

        <button *ngIf="!showHidden" mat-icon-button (click)="showHidden = true; onFilterChange('showHidden')"
          matTooltip="Laat verstopte deelnemers zien">
          <mat-icon>visibility</mat-icon>
        </button>
        <button *ngIf="showHidden" mat-icon-button (click)="showHidden = false; onFilterChange('showHidden')"
          matTooltip="Laat verstopte deelnemers niet meer zien">
          <mat-icon>visibility_off</mat-icon>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="orderMenu">
          <mat-icon class="secondary-text">sort</mat-icon>
        </button>

        <mat-menu #orderMenu="matMenu">
          <button [class.active]="orderBy == 'name' && orderDirection == 'asc'" mat-menu-item (click)="
              orderBy = 'name'; orderDirection = 'asc'; onFilterChange('order')
            ">
            <span>Naam <span class="small">(A tot Z)</span></span>
          </button>
          <button [class.active]="orderBy == 'name' && orderDirection == 'desc'" mat-menu-item (click)="
              orderBy = 'name'; orderDirection = 'desc'; onFilterChange('order')
            ">
            <span>Naam <span class="small">(Z tot A)</span></span>
          </button>
          <!-- <button [class.active]="orderBy == 'createdAt' && orderDirection == 'asc'" mat-menu-item
                        (click)="orderBy = 'createdAt'; orderDirection = 'asc'; onFilterChange('order')">
                        <span>Registratiedatum <span class="small">(Nieuwer)</span></span>
                    </button> -->
          <button [class.active]="orderBy == 'cardId' && orderDirection == 'asc'" mat-menu-item (click)="
              orderBy = 'cardId';
              orderDirection = 'asc';
              onFilterChange('order')
            ">
            <span>Deelnemers zonder kaart bovenaan</span>
          </button>
        </mat-menu>
      </mat-action-list>
    </div>
    <mat-table #table [dataSource]="users">
      <!-- Checkbox Column -->
      <!-- <ng-container matColumnDef="checkbox">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-checkbox [(ngModel)]="checkboxes[user.id]" (ngModelChange)="onSelectedChange(user.id)"
                    (click)="$event.stopPropagation()">
                </mat-checkbox>
            </mat-cell>
        </ng-container> -->

      <!-- Avatar Column -->
      <!-- <ng-container matColumnDef="avatar">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let user">
                <img class="avatar" *ngIf="user.avatar" [alt]="user.name" [src]="user.avatar" />
            </mat-cell>
        </ng-container> -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
        <mat-cell *matCellDef="let user">
          <span *ngIf="user.paymentFailed" matTooltip="Er is een fout opgetreden bij het betalen.">
            <mat-icon class="warn-icon">error</mat-icon>
          </span>
          <p class="text-truncate">
            {{ user.name }} {{ user.lastName }}
            <span *ngIf="user.credits">({{ user.credits }})</span>
          </p>
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>E-mail</mat-header-cell>
        <mat-cell *matCellDef="let user">
          <p class="email text-truncate">
            {{ user.email }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Telefoonnummer</mat-header-cell>
        <mat-cell *matCellDef="let user" fxHide fxShow.gt-md>
          <p class="phone text-truncate">
            {{ user.phoneNumber }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="registrationDate">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Registratiedatum</mat-header-cell>
        <mat-cell *matCellDef="let user" fxHide fxShow.gt-md>
          <p class="phone text-truncate" *ngIf="user.createdAt">
            {{ user.createdAt | date: 'd/MM/yyyy' }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cardId">
        <mat-header-cell *matHeaderCellDef>Kaartnummer</mat-header-cell>
        <mat-cell *matCellDef="let user">
          <p class="phone text-truncate">
            {{ user.cardId }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
          <div fxFlex="row" fxLayoutAlign="end center">
            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" (click)="$event.stopPropagation()">
              <mat-icon class="secondary-text">more_vert</mat-icon>
            </button>

            <mat-menu #moreMenu="matMenu">
              <button mat-menu-item (click)="showCreditsHistory(user)">
                <span>Credits historie</span>
              </button>
              <button mat-menu-item (click)="changeCredits('add', user)">
                <span>Credits toevoegen</span>
              </button>
              <button mat-menu-item (click)="changeCredits('remove', user)">
                <span>Credits verminderen</span>
              </button>
              <a href="mailto:{{ user.email }}" *ngIf="env.showSendEmail">
                <button mat-menu-item>
                  <span>Verstuur e-mail</span>
                </button>
              </a>
              <button mat-menu-item (click)="copyEmail(user.email)">
                <span>Kopieer e-mail</span>
              </button>
              <button *ngIf="!user.hidden" mat-menu-item (click)="changeHiddenStatus('hide', user)">
                <span>Verstop deelnemer</span>
              </button>
              <button *ngIf="user.hidden" mat-menu-item (click)="changeHiddenStatus('show', user)">
                <span>Deelnemer weer laten zien</span>
              </button>
              <button mat-menu-item (click)="deleteUser(user)">
                <span>Verwijder deelnemer</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let user; columns: displayedColumns" class="user" (click)="editUser(user)" matRipple>
      </mat-row>
    </mat-table>
  </mat-card>
</div>
<button mat-fab class="fab-fixed" (click)="newUser()">
  <mat-icon>add</mat-icon>
</button>