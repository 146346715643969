import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface History {
  description: string;
  remarks: string;
  date: Timestamp;
  creditsSpent: number;
  creditsAdded: number;
  actionBy: string;
  userData: any;
  userRef: any;
}
export interface HistoryId extends History {
  id: string;
}
export interface User {
  name: string;
  credits: number;
}
export interface UserId extends User {
  id: string;
}
interface DateFilter {
  value: string;
  viewValue: string;
}
interface Year {
  value: number;
  viewValue: string;
}
interface Month {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AuditComponent implements OnInit {
  itemsToShow = 20;
  usersCollection: AngularFirestoreCollection<User>;
  $users: Observable<UserId[]>;
  users: UserId[];
  history: HistoryId[];
  charges: HistoryId[];
  chargesCollection: AngularFirestoreCollection<History>;
  payments: HistoryId[];
  paymentCollection: AngularFirestoreCollection<History>;
  displayedColumns = [
    'name',
    'credits',
    'date',
    'description',
    'remarks',
    'actionBy',
  ]; // add "buttons" to show the button column
  totalUsers: number;
  orderBy = 'name';
  orderByChanged: Subject<string> = new Subject<string>();
  orderDirection = 'asc';
  orderDirectionChanged: Subject<string> = new Subject<string>();
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  $dataUpdated: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public $filteredHistory: ReplaySubject<UserId[]> = new ReplaySubject<
    UserId[]
  >(1);
  filteredHistory: UserId[];
  dateForm: FormGroup;
  dateFilter: DateFilter[] = [
    { value: 'year', viewValue: 'Jaar' },
    { value: 'month', viewValue: 'Maand' },
  ];
  years: Year[] = [];
  currentYear;
  months: Month[] = [
    { value: 0, viewValue: 'Januari' },
    { value: 1, viewValue: 'Febuari' },
    { value: 2, viewValue: 'Maart' },
    { value: 3, viewValue: 'April' },
    { value: 4, viewValue: 'Mei' },
    { value: 5, viewValue: 'Juni' },
    { value: 6, viewValue: 'Juli' },
    { value: 7, viewValue: 'Augustus' },
    { value: 8, viewValue: 'September' },
    { value: 9, viewValue: 'Oktober' },
    { value: 10, viewValue: 'November' },
    { value: 11, viewValue: 'December' },
  ];
  currentMonth: any;
  selectedYear: any;
  selectedMonth: any;
  selectedFilter: any;
  // dateFrom: Date;
  // dateTo: Date;

  @ViewChild('TABLE', { static: false }) table: ElementRef;

  constructor(
    public db: AngularFirestore,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const years: Year[] = [];
    let yearInStep = currentYear;
    for (let step = 0; step < 10; step++) {
      years.push({ value: yearInStep, viewValue: yearInStep.toString() });
      yearInStep = yearInStep - 1;
    }
    this.years = years;
    this.years.forEach((year) => {
      console.log(year.value === currentYear);
      if (year.value === currentYear) {
        this.selectedYear = year.value;
      }
    });
    this.months.forEach((month) => {
      console.log(month.value === currentMonth);
      if (month.value === currentMonth) {
        this.selectedMonth = month.value;
      }
    });
    this.selectedFilter = this.dateFilter[1].value;
    this.usersCollection = this.db.collection<User>('users');
    this.$users = this.usersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as User;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(2)
    );
    // Gets the filtered list
    this.getAuditData();

    // Changes the list when dateFrom changed
    this.combineObservables();

    this.$filteredHistory.subscribe((history) => {
      this.filteredHistory = history;
    });
  }
  combineObservables() {
    const searchFilter = combineLatest(
      this.$users,
      this.searchQueryChanged,
      this.orderByChanged,
      this.orderDirectionChanged,
      this.$dataUpdated
    );
    searchFilter.pipe(debounceTime(300)).subscribe((values) => {
      console.log('values', values);
      let history = [];
      const users = values[0];
      const searchQuery = values[1];
      const filters = {
        orderBy: values[2],
        orderDirection: values[3],
      };
      if (!this.charges || !this.payments) {
        return;
      }
      this.charges.forEach((charge) => {
        users.forEach((user) => {
          if (user.id === charge.userRef.id) {
            charge.userData = user;
            history.push(charge);
          }
        });
      });
      this.payments.forEach((payment) => {
        users.forEach((user) => {
          if (user.id === payment.userRef.id) {
            payment.userData = user;
            history.push(payment);
          }
        });
      });
      history = history.sort((x, y) => {
        return x.date.toDate() - y.date.toDate();
      });
      this.history = history.reverse();
      console.log('this.history', this.history);

      const filteredHistory = history.filter((item) => this.checkFilters(item));

      this.totalUsers = filteredHistory.length;
      console.log('filteredHistory', filteredHistory);
      this.$filteredHistory.next(filteredHistory);
    });
    this.searchQueryChanged.next('');
    this.orderByChanged.next(this.orderBy);
    this.orderDirectionChanged.next(this.orderDirection);
    this.searchQueryChanged.subscribe((value) => {
      console.log('searchQueryChanged', value);
    });
  }
  async getAuditData() {
    console.log('this.selectedFilter', this.selectedFilter);
    console.log('this.selectedYear', this.selectedYear);
    console.log('this.selectedMonth', this.selectedMonth);
    this.chargesCollection = this.db.collection<History>('charges', (ref) =>
      ref.where('year', '==', this.selectedYear)
    );
    this.paymentCollection = this.db.collection<History>('payments', (ref) =>
      ref.where('year', '==', this.selectedYear)
    );
    if (this.selectedFilter === 'month') {
      this.chargesCollection = this.db.collection<History>('charges', (ref) =>
        ref
          .where('year', '==', this.selectedYear)
          .where('month', '==', this.selectedMonth)
      );
      this.paymentCollection = this.db.collection<History>('payments', (ref) =>
        ref
          .where('year', '==', this.selectedYear)
          .where('month', '==', this.selectedMonth)
      );
    }
    this.charges = await this.chargesCollection
      .get()
      .toPromise()
      .then((chargesSnap) => {
        const charges: HistoryId[] = [];
        chargesSnap.forEach((doc) => {
          charges.push(doc.data() as HistoryId);
        });
        this.$dataUpdated.next(true);
        return charges;
      });

    this.payments = await this.paymentCollection
      .get()
      .toPromise()
      .then((paymentsSnap) => {
        const payments: HistoryId[] = [];
        paymentsSnap.forEach((doc) => {
          payments.push(doc.data() as HistoryId);
        });
        this.$dataUpdated.next(true);
        return payments;
      });

    // TODO: !!!!this is triggered before any data is received... entire async of this page is broken!
    // just quick fix this with async gets for now..
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    } else if (type === 'order') {
      this.orderByChanged.next(this.orderBy);
      this.orderDirectionChanged.next(this.orderDirection);
    }
  }
  checkFilters(history) {
    console.log(history.remarks);
    this.itemsToShow = 20;
    // let passesHiddenFilter = true;
    let passesSearchFilter = true;

    // if (filters.showHidden && history.hidden || !filters.showHidden && !history.hidden) {
    //   passesHiddenFilter = true;
    // } else {
    //   passesHiddenFilter = false;
    // }
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (history.userData.name) {
        if (
          history.userData.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          console.log('Passed name');
          passesSearchFilter = true;
        }
      }
      if (history.actionBy) {
        if (
          history.actionBy
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          console.log('Passed actionBy');
          passesSearchFilter = true;
        }
      }
      if (history.description) {
        if (
          history.description
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          console.log('Passed description');
          passesSearchFilter = true;
        }
      }
      if (history.remarks) {
        if (
          history.remarks.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          console.log('Passed remarks');
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return history;
    }
  }
  exportTableToExcel() {
    console.log(this.table);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement,
      {
        dateNF: 'dd/mm/yyyy',
      }
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    console.log('ws', ws);
    console.log('wb', wb);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Audits.xlsx');
  }

  async exportVisibleAudit() {
    const exportHistory = [];
    let errorCounter = 0;

    this.filteredHistory.forEach((entry) => {
      try {
        const exportEntry: any = {};
        exportEntry.Naam = entry['userData']['name'];
        exportEntry.Datum = entry['date'].toDate();
        if (entry['creditsSpent']) {
          exportEntry.Credits = -entry['creditsSpent'];
        } else if (entry['creditsAdded']) {
          exportEntry.Credits = entry['creditsAdded'];
        }
        exportEntry.Beschrijving = entry['description'];
        exportEntry.Opmerkingen = entry['remarks'];
        exportEntry.Behandelaar = entry['actionBy'];
        exportHistory.push(exportEntry);
      } catch (err) {
        errorCounter++;
      }
    });
    if (errorCounter) {
    }
    console.log('exportHistory', exportHistory);
    let msg;
    if (exportHistory.length > 0) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportHistory, {
        cellDates: true,
        dateNF: 'dd/mm/yyyy',
      }); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Audits');

      // /* save to file */
      const date = new Date();
      XLSX.writeFile(wb, `Audit-Export.xlsx`);
      msg = 'Bestand geëxporteerd.';
    } else {
      // Nothing to export
      msg = 'Geen exporteerbare data binnen geselecteerde filters.';
    }
    if (errorCounter) {
      msg = msg + ` ${errorCounter} rijen konden niet worden verwerkt.`;
    }
    this.snackBar.open(msg, '', {
      duration: 5000,
    });
  }
}
