import { Component, OnInit, Inject, NgZone } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, Subject, combineLatest, ReplaySubject } from 'rxjs';
import { map, take, debounceTime, takeUntil } from 'rxjs/operators';
import { User, UserId } from '../../../../../interfaces';
import { IconId } from 'src/app/users/dialogs/user/user.component';
import { MapsAPILoader } from '@agm/core';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface DialogData {
  user: any;
  users: Array<object>;
  icons: Array<IconId>;
}

export interface RideUser {
  id: string;
  userRef: AngularFirestoreDocument;
  title: string;
  address: string;
  from: string;
  fromType: string;
  to: string;
  toType: string;
  order: number;
  remarks: string;
  commentsForDriver: string;
}

export interface Address {
  name: string;
  address: string;
}
export interface AddressId extends Address {
  id: string;
}

export interface Product {
  id: string;
  name: string;
  default: boolean;
  cost: number;
  color: string;
}
export interface ProductId extends Product {
  id: string;
}

@Component({
  selector: 'app-user-ride',
  templateUrl: './user-ride.component.html',
  styleUrls: ['./user-ride.component.scss'],
})
export class UserRideComponent implements OnInit {
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  // searchForm: FormGroup;
  userForm: FormGroup;
  usersCollection: AngularFirestoreCollection<User>;
  users: Observable<UserId[]>;
  public filteredUsers: ReplaySubject<UserId[]> = new ReplaySubject<UserId[]>(
    1
  );
  loading: boolean;
  itemsShown = 0;
  potentialItems = 0;
  autoCompleteOpen: boolean;
  userSelected: boolean;
  fromHidden = false;
  toHidden = false;
  user: object;
  locationTypes = [
    { val: 'custom', name: 'Adres invullen' },
    { val: 'home', name: 'Thuisadres gebruiken' },
    { val: 'addressFromDb', name: 'Standaardlocatie gebruiken' },
  ];
  addressesCollection: AngularFirestoreCollection<Address>;
  addresses: ReplaySubject<AddressId[]> = new ReplaySubject<AddressId[]>(1);
  addressesTo: ReplaySubject<AddressId[]> = new ReplaySubject<AddressId[]>(1);
  addressesFrom: ReplaySubject<AddressId[]> = new ReplaySubject<AddressId[]>(1);
  addressesArray: Array<AddressId>;
  addressesDb: Observable<AddressId[]>;
  productCollection: AngularFirestoreCollection<Product>;
  products: Observable<ProductId[]>;
  productsArray: Array<Product>;
  placeService: google.maps.places.AutocompleteService;
  // predictionFrom: Observable<string[]>;
  public predictionFrom: ReplaySubject<string[]> = new ReplaySubject<string[]>(
    1
  );
  public predictionTo: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  searchFromAddresses: Array<any>;
  searchToAddresses: Array<any>;
  // predictionTo: Observable<string[]>;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  nothingFound: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserRideComponent>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.placeService = new google.maps.places.AutocompleteService();
    });
    if (this.data.user.title) {
      this.userSelected = true;
      this.user = this.data.user;
    } else {
      this.usersCollection = this.db.collection<User>('users/');
      this.users = this.usersCollection.snapshotChanges().pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as User;
            if (!data.credits) {
              data.credits = 0;
            }
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        ),
        take(2)
      );
      const combinedItemsFilterObversable = combineLatest(
        this.users,
        this.searchQueryChanged
      );
      combinedItemsFilterObversable
        .pipe(
          takeUntil(this._onDestroy),
          debounceTime(300),
          map((combinedData) => {
            const items = combinedData[0];
            const search = combinedData[1];
            this.searchQuery = search;
            console.log('items', items);
            console.log('search', search);
            if (!combinedData[0]) {
              return [];
            }
            // simulate server fetching and filtering data
            this.itemsShown = 0;
            this.potentialItems = 0;
            if (this.searchQuery === '') {
              return [];
            }
            return items.filter((item) => this.checkFilters(item));
          })
        )
        .subscribe(
          (filteredItems) => {
            console.log('filteredItems', filteredItems);
            this.filteredUsers.next(filteredItems);
          },
          (error) => console.log('error', error)
        );
    }
    let fromType = 'custom';
    if (this.data.user.fromType) {
      fromType = this.data.user.fromType;
    }
    let toType = 'custom';
    if (this.data.user.toType) {
      toType = this.data.user.toType;
    }
    this.userForm = this.fb.group({
      from: [this.data.user.from, Validators.required],
      fromType: [fromType, Validators.required],
      to: [this.data.user.to, Validators.required],
      toType: [toType, Validators.required],
      remarks: [this.data.user.remarks],
      commentsForDriver: [this.data.user.commentsForDriver],
      rideProduct: [
        this.data.user.rideProduct ? this.data.user.rideProduct : 'none',
      ],
      fromSearch: [],
      toSearch: [],
    });
    this.userForm.controls['from'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((addressString) => {
        console.log('addressString from', addressString);
        console.log(
          'this.userForm.value.fromType',
          this.userForm.value.fromType
        );

        if (
          this.userForm.value.fromType === 'custom' &&
          addressString &&
          addressString.length !== 0
        ) {
          console.log('update suggestions');
          // getPredictions(addressString)
          this.placeService.getQueryPredictions(
            { input: addressString + ' Nederland' },
            (addresses) => {
              console.log('addresses', addresses);
              const array = [];
              this.searchFromAddresses = [];
              if (addresses) {
                this.searchFromAddresses = addresses;
                addresses.forEach((address) => {
                  array.push(address.description);
                });
              }
              this.ngZone.run(() => {
                this.predictionFrom.next(array);
              });
            }
          );
        } else {
          return this.ngZone.run(() => {
            this.predictionFrom.next([]);
          });
        }
      });
    this.userForm.controls['to'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((addressString) => {
        console.log('addressString to', addressString);
        console.log('this.userForm.value.toType', this.userForm.value.toType);
        const array = [];
        // this.searchFromAddresses = [];
        if (
          this.userForm.value.toType === 'custom' &&
          addressString &&
          addressString.length !== 0
        ) {
          console.log('update suggestions');
          this.placeService.getQueryPredictions(
            { input: addressString + ' Nederland' },
            (addresses) => {
              // this.searchFromAddresses = addresses;
              console.log('addresses', addresses);
              if (addresses) {
                addresses.forEach((address) => {
                  array.push(address.description);
                });
              }
              this.predictionTo.next(array);
              this.ngZone.run(() => {
                this.predictionFrom.next(array);
              });
            }
          );
        } else {
          return this.ngZone.run(() => {
            this.predictionFrom.next([]);
          });
        }
      });
    this.search('fromSearch', this.addressesFrom);
    this.search('toSearch', this.addressesTo);

    this.data.icons.forEach((icon) => {
      // console.log('icon', icon);
      if (icon.editableInRide) {
        if (this.data.user) {
          this.userForm.registerControl(
            icon.id,
            new FormControl(
              this.data.user[icon.id] ? this.data.user[icon.id] : false
            )
          );
        } else {
          this.userForm.registerControl(icon.id, new FormControl(false));
        }
      }
    });
    this.productCollection = this.db.collection<Product>('products', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.products = this.productCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Product;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.products.subscribe((products) => {
      // console.log('products', products);
      this.productsArray = products;
      if (this.data.user.rideProduct) {
        const selectedProduct = products.find((product) => {
          return product.id === this.data.user.rideProduct.id;
        });
        console.log('selectedProduct', selectedProduct);
        this.userForm.patchValue({ rideProduct: selectedProduct.id });
      }
    });
    if (this.data.user.fromType === 'home') {
      this.userForm.controls.from.disable();
    }
    if (this.data.user.toType === 'home') {
      this.userForm.controls.to.disable();
    }
    this.addressesCollection = this.db.collection<Address>('addresses', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.addressesDb = this.addressesCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Address;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.addressesDb.subscribe((addresses) => {
      // console.log('addresses', addresses);
      this.addressesArray = addresses;
      this.addressesTo.next(this.addressesArray);
      this.addressesFrom.next(this.addressesArray);
      if (this.data.user.fromType === 'addressFromDb') {
        this.fromHidden = true;
      }
      if (this.data.user.toType === 'addressFromDb') {
        this.toHidden = true;
      }
      if (this.data.user.to) {
        const selectedAddresses = addresses.find((address) => {
          return address.id === this.data.user.to.id;
        });
        console.log('selectedAddresses', selectedAddresses);
        this.userForm.patchValue({ to: selectedAddresses.id });
      }
      if (this.data.user.from) {
        const selectedAddresses = addresses.find((address) => {
          return address.id === this.data.user.from.id;
        });
        console.log('selectedAddresses', selectedAddresses);
        this.userForm.patchValue({ from: selectedAddresses.id });
      }
    });
  }
  onIconChange(iconId, ev) {
    this.user[iconId] = ev.source.checked;
  }

  onFilterChange(type, ev, id) {
    this.searchQueryChanged.next(ev);
  }

  checkFilters(item) {
    // console.log('item', item);
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (item.name) {
        if (item.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
      if (item.streetName) {
        if (
          item.streetName.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
        if (item.houseNumber) {
          var searchAdres =
            item.streetName.toLowerCase() +
            ' ' +
            item.houseNumber.toLowerCase();
          if (searchAdres.includes(this.searchQuery.toLowerCase())) {
            passesSearchFilter = true;
          }
        }
      }
      if (item.postal) {
        if (
          item.postal.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (item.address) {
        if (
          item.address.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (item.email) {
        if (item.email.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
      if (item.description) {
        if (
          item.description
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      this.potentialItems = this.potentialItems + 1;
      if (this.itemsShown === 10) {
        console.log('item not shown but passes search', item);
        return;
      } else {
        console.log('item shown', item);
        this.itemsShown = this.itemsShown + 1;
        return item;
      }
    }
  }

  onTypeChange(type, ev) {
    console.log('ev', ev.value);
    this.userForm.controls[type].enable();
    if (ev.value === 'custom') {
      const patchObj = {};
      patchObj[type] = '';
      this.userForm.patchValue(patchObj);
    } else if (ev.value === 'home') {
      const patchObj = {};
      if (this.user['address']) {
        patchObj[type] = this.user['address'];
        console.log('patchObj', patchObj);
        this.userForm.controls[type].disable();
      } else {
        patchObj[type + 'Type'] = 'custom';
        this._snackBar.open(
          'Er is geen thuis adres ingevuld, vul a.u.b. handmatig in.',
          'X',
          {
            duration: 10000,
          }
        );
      }
      this.userForm.patchValue(patchObj);
    }
    if (ev.value === 'addressFromDb') {
      if (type === 'from') {
        this.fromHidden = true;
      }
      if (type === 'to') {
        this.toHidden = true;
      }
    } else {
      if (type === 'from') {
        this.fromHidden = false;
      }
      if (type === 'to') {
        this.toHidden = false;
      }
    }
  }

  select(user) {
    if (typeof user === 'object') {
      this.userSelected = true;
      console.log('user', user);
      const userObj = {
        id: user.id,
        title: user.name,
      };
      if (user.streetName) {
        userObj['address'] =
          user.streetName +
          ' ' +
          user.houseNumber +
          ', ' +
          user.postal +
          ' ' +
          user.city;
      }
      if (!user.icons) {
        user.icons = {};
      }
      this.data.icons.forEach((icon) => {
        console.log('icon', icon);
        if (icon.editableInRide) {
          this.userForm.patchValue({
            [icon.id]: user.icons[icon.id] ? user.icons[icon.id] : false,
          });
          // this.userForm.registerControl(icon.id, new FormControl(
          //   (user.icons[icon.id]) ? user.icons[icon.id] : false));
        }
        if (user.icons[icon.id]) {
          userObj[icon.id] = true;
        }
      });
      if (user.commentsForDriver) {
        userObj['commentsForDriver'] = user.commentsForDriver;
        this.userForm.patchValue({ commentsForDriver: user.commentsForDriver });
      }
      if (user.remarks) {
        userObj['remarks'] = user.remarks;
        this.userForm.patchValue({ remarks: user.remarks });
      }
      if (user.rideProductId) {
        const defaultProduct = this.productsArray.find((product) => {
          return product.id === user.rideProductId;
        });
        console.log('defaultProduct', defaultProduct);
        this.userForm.patchValue({ rideProduct: defaultProduct.id });
      }
      this.user = userObj;
      console.log('this.user', this.user);
      // this.user.id = user.id;
      // this.user.title = user.name;
      // this.user.address = user.address;
    }
  }
  save() {
    console.log('this.user', this.user);
    console.log('userForm', this.userForm.value);
    const selectFromAddres = this.addressesArray.find((address) => {
      return address['id'] === this.userForm.value.from;
    });
    if (selectFromAddres) {
      this.user['from'] = selectFromAddres;
    } else {
      this.user['from'] = this.userForm.value.from;
    }
    this.user['fromType'] = this.userForm.value.fromType;
    const selectToAddres = this.addressesArray.find((address) => {
      return address['id'] === this.userForm.value.to;
    });
    if (selectToAddres) {
      this.user['to'] = selectToAddres;
    } else {
      this.user['to'] = this.userForm.value.to;
    }
    this.user['toType'] = this.userForm.value.toType;
    const selectedProduct = this.productsArray.find((product) => {
      return product['id'] === this.userForm.value.rideProduct;
    });
    this.user['rideProduct'] = selectedProduct;
    if (!this.user['rideProduct']) {
      delete this.user['rideProduct'];
    }

    this.data.icons.forEach((icon) => {
      if (icon.editableInRide) {
        console.log(this.userForm.value[icon.id]);
        if (this.userForm.value[icon.id]) {
          this.user[icon.id] = this.userForm.value[icon.id];
          console.log(this.user[icon.id]);
        } else {
          delete this.user[icon.id];
          console.log(this.user[icon.id]);
        }
      }
    });
    if (this.user['fromType'] === 'home') {
      this.user['from'] = this.user['address'];
    }
    if (this.user['toType'] === 'home') {
      this.user['to'] = this.user['address'];
    }
    if (this.userForm.value.commentsForDriver) {
      this.user['commentsForDriver'] = this.userForm.value.commentsForDriver;
    }
    if (this.userForm.value.remarks) {
      this.user['remarks'] = this.userForm.value.remarks;
    }
    console.log('this.user', this.user);
    this.dialogRef.close(this.user);
  }

  clearInput(type) {
    console.log('type', type);
    this.nothingFound = false;
    if (type === 'to') {
      this.userForm.controls['toSearch'].setValue('');
    } else if (type === 'from') {
      this.userForm.controls['fromSearch'].setValue('');
    }
  }

  search(type: string, addressArray: ReplaySubject<AddressId[]>) {
    this.userForm.controls[type].valueChanges
      .pipe(debounceTime(500))
      .subscribe((addressString) => {
        this.nothingFound = false;
        this.searchQuery = addressString;
        this.potentialItems = 0;
        this.itemsShown = 0;
        // const filteredAddress = [];
        console.log('addressesArray', this.addressesArray);
        const newAddressArray = [];
        this.addressesArray.forEach((element) => {
          const item = this.checkFilters(element);
          // console.log('item', item);
          if (typeof item !== 'undefined') {
            newAddressArray.push(item);
          }
        });
        if (newAddressArray.length <= 0) {
          this.nothingFound = true;
        }
        console.log('newAddressArray', newAddressArray);
        addressArray.next(newAddressArray);
      });
  }
}
