import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import {
  Observable,
  Subject,
  ReplaySubject,
  combineLatest,
  BehaviorSubject,
  of,
} from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ImportExportComponent } from '../dialog/import-export/import-export.component';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { CreateEditVehiclesComponent } from './dialogs/create-edit-vehicles/create-edit-vehicles.component';
import { WarningComponent } from '../dialog/warning/warning.component';
import { Setting } from './../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Vehicle {
  name: string;
  calendarStyle: any;
  seats: number;
  active: boolean;
}
export interface VehicleId extends Vehicle {
  id: string;
}
@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<VehicleId[]>;
  public filteredVehicles: ReplaySubject<VehicleId[]> = new ReplaySubject<
    VehicleId[]
  >(1);
  displayedColumns = ['name', 'seats', 'color', 'button'];
  importedData: Subject<VehicleId[]> = new ReplaySubject<VehicleId[]>();
  totalVehicles: number;
  totalActive = 0;
  header = true;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  csvRecords: any[] = [];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  maxTotalVehicle: number;
  // tslint:disable-next-line: variable-name
  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.settingDoc = this.db.doc<Setting>('settings/general');
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      this.maxTotalVehicle = val.maxActiveVehicles;
      console.log('this.settings', val);
    });
    this.vehicleCollection = this.db.collection<Vehicle>('vehicles', (ref) =>
      ref.orderBy('name', 'asc')
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.vehicles.subscribe((value) => {
      console.log('vehicles', value);
      let totalActive = 0;
      value.forEach((val) => {
        if (val.active === true) {
          totalActive = totalActive + 1;
          console.log('totalActive', totalActive);
        }
      });
      this.totalActive = totalActive;
      this.totalVehicles = value.length;
      console.log('this.totalActive', this.totalActive);
    });
    const combinedObservable = combineLatest(
      this.vehicles,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const vehicles = values[0];
        const searchQuery = values[1];
        // const filters = {
        //   showHidden: values[2],
        //   orderBy: values[3],
        //   orderDirection: values[4]
        // };
        let filteredVehicles = vehicles.filter((item) =>
          this.checkFilters(item)
        );
        // this.orderDirection = 'desc';
        // console.log('orderBy', filters.orderBy);
        // filteredVehicles = filteredVehicles.sort((a, b) => {
        //   if (a[filters.orderBy] > b[filters.orderBy]) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }
        //   // return 0;
        // });
        // if (filters.orderDirection === 'desc') {
        //   filteredVehicles.reverse();
        // }
        console.log('filteredVehicles', filteredVehicles);
        this.totalVehicles = filteredVehicles.length;
        this.filteredVehicles.next(filteredVehicles);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQueryChanged.next('');
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(vehicle) {
    // console.log('user', user);
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (vehicle.name) {
        if (
          vehicle.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return vehicle;
    }
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result === 'Export') {
        this.exportTableToExcel();
      }
      if (result === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }

  editVehicle(vehicle?) {
    const dialogRef = this.dialog.open(CreateEditVehiclesComponent, {
      width: '300px',
      data: { vehicle, totalActive: this.totalActive },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  newVehicle() {
    const dialogRef = this.dialog.open(CreateEditVehiclesComponent, {
      width: '300px',
      data: { totalActive: this.totalActive },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  async deactivateVehicle(vehicle) {
    // await this.db.collection('vehicles').doc(vehicle.id).delete();
  }
  uploadedFile(event) {
    if (this.uploader.nativeElement.value === null) {
      return;
    } else {
      console.log('event', event);
      console.log('uploading file');
      this.fileUploaded = event.target.files[0];
      this.readExcel();
    }
  }
  readExcel() {
    const readFile = new FileReader();
    const spreadsheet = {};
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins') {
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'naam':
            columnNames[key] = 'name';
            break;
          case 'zitplaatsen':
            columnNames[key] = 'seats';
            break;
          case 'kleur':
            columnNames[key] = 'color';
            break;
          case 'actief':
            columnNames[key] = 'active';
            break;
        }
      });
      delete spreadsheet[1];
      const importedData = [];
      let totalActive = 0;
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {};
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log(spreadsheet[key][colKey]);
          // console.log('colName', colName);
          if (colName) {
            if (colName === 'name') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'seats') {
              spreadsheet[key][colKey] = Number(spreadsheet[key][colKey]);
            }
            if (colName === 'color') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'active') {
              spreadsheet[key][colKey] = Boolean(spreadsheet[key][colKey]);
              if (spreadsheet[key][colKey] === true) {
                totalActive = totalActive + 1;
              }
            }
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });
        console.log('rowObj', rowObj);
        importedData.push(rowObj);
      });
      console.log('totalActive', totalActive);
      console.log('this.maxTotalVehicle', this.maxTotalVehicle);
      if (totalActive >= this.maxTotalVehicle) {
        console.log('tomuch active vehicles');
        const dialogRef = this.dialog.open(WarningComponent, {
          width: '300px',
          data: {
            text: `Er mogen maximaal ${this.maxTotalVehicle} actieve voertuigen zijn. Pas dit aan en probeer opnieuw.`,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {});
        return;
      }
      this.importedData.next(importedData);
      this.fileChangeListener();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async exportTableToExcel() {
    const vehiclesRef = this.db.collection<Vehicle>('vehicles');
    const vehiclesArray = [];
    const vehiclesObservable = await vehiclesRef.get();
    await vehiclesObservable.forEach((vehicles) => {
      // console.log('vouchers', vouchers);
      vehicles.forEach((vehicleDoc) => {
        const vehicle = vehicleDoc.data() as Vehicle;
        const exportVehicleObj = {};
        exportVehicleObj['Naam'] = vehicle.name ? vehicle.name : '';
        exportVehicleObj['Zitplaatsen'] = vehicle.seats ? vehicle.seats : '';
        exportVehicleObj['Kleur'] = vehicle.calendarStyle.color
          ? vehicle.calendarStyle.color
          : '';
        exportVehicleObj['Actief'] = vehicle.active ? vehicle.active : '';
        vehiclesArray.push(exportVehicleObj);
      });
    });
    if (vehiclesArray.length > 0) {
      console.log('vouchers', vehiclesArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vehiclesArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vehicles');

      // /* save to file */
      XLSX.writeFile(wb, 'voertuigen.xlsx');
    } else {
      // Nothing to export
    }
  }

  // Your applications input change listener for the CSV File
  fileChangeListener() {
    this.vehicleCollection = this.db.collection<Vehicle>('vehicles');
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.vehicles.subscribe((value) => {
      console.log('vehicles', value);
      this.totalVehicles = value.length;
    });
    this.importedData.subscribe((val) => {
      console.log('this.importedData', val);
    });
    try {
      const combinedObservable = combineLatest(
        this.vehicles,
        this.importedData
      );
      combinedObservable
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((res: [VehicleId[], Array<any>]) => {
          console.log('res', res);
          const existingVehicles = res[0];
          const importedVehicles = res[1];
          if (importedVehicles === null) {
            return;
          }
          // check for existingVolunteers with importedVolunteers' data, update their info.
          // create new entry for all newly imported volunteers, process account creation in functions trigger.
          importedVehicles.forEach(async (vehicleData) => {
            console.log('vehicleData', vehicleData);
            let seats;
            let name;
            // tslint:disable-next-line: prefer-const
            let calendarStyle: any = {};
            let active;
            // this section looks for a recognized column title and matches it with the correct userData
            if (vehicleData.name) {
              name = vehicleData.name;
            }
            if (vehicleData.seats) {
              seats = +vehicleData.seats;
            }
            if (vehicleData.color) {
              calendarStyle.color = vehicleData.color;
            }
            if (vehicleData.active) {
              active = vehicleData.active;
            } else {
              active = false;
            }
            const importData: Vehicle = { seats, name, calendarStyle, active };
            //
            const existingData = existingVehicles.find((element) => {
              return element.name === importData.name;
            });

            console.log('existingData', existingData);
            if (existingData) {
              // update existing doc

              await this.vehicleCollection
                .doc(existingData.id)
                .set(importData, { merge: true });
            } else {
              // create new doc
              await this.vehicleCollection.add(importData);
            }
            this.importedData.next(null);
            this.uploader.nativeElement.value = null;
          });

          this._snackBar.open('De voertuigen zijn geimporteerd', 'X', {
            duration: 5000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
}
